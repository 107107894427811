import React, { useState } from 'react'
import { useQuery } from 'react-apollo'
import { LIST_PROFILES } from '../../constants/Profile'
import Scrollbar from '../Scrollbar'
import { LoadingInline } from '../../components/Handlers'

function AdminUsers(props) {
  const [matching, setMatching] = useState('')
  const { loading, data } = useQuery(LIST_PROFILES, {
    variables: { matching: matching }
  })

  let profiles = []
  let total = 0
  if (data) {
    profiles = data.profiles.results
    total = data.profiles.total
  }
  // className="scroll1">
  return (
    <Scrollbar>
      <div className="ma0 pa0 pa3-m pa4-l white bg-frame flex-center flex-column">
        <div className="flex items-center justify-between w-100">
          <input
            type="text"
            value={matching}
            onChange={(ev) => setMatching(ev.target.value)}
            placeholder="search"
          />
          <div className="ml-auto">
            results: {profiles.length} of {total}
          </div>
        </div>
        <table className="w-100">
          <thead>
            <tr>
              <th className="tl">a</th>
              <th className="tl">Sub</th>
              <th className="tl">Name</th>
              <th className="tl">Handle</th>
              <th className="tl">Email</th>
              <th className="tl">Last</th>
              <th className="tl">Roles</th>
            </tr>
          </thead>
          <tbody>
            {loading ? (
              <LoadingInline />
            ) : (
              profiles.map((profile, x) => {
                let color = {}
                if (!(x % 2)) {
                  color = { backgroundColor: 'var(--p-lighten)' }
                }
                return (
                  <tr key={profile.id}>
                    <td style={color} className="tc">
                      {profile.settings &&
                      profile.settings.authAllowed &&
                      profile.settings.authAllowed.google ? (
                        <i className="fab fa-google" />
                      ) : (
                        ''
                      )}
                    </td>
                    <td style={color} className="tc">
                      {profile.subscriptions.length > 0 ? (
                        <i className="fas fa-check-square" />
                      ) : (
                        <></>
                      )}
                    </td>
                    <td style={color}>
                      <div style={{ fontSize: '4pt' }}>{profile.id}</div>
                      <div>{profile.name}</div>
                    </td>
                    <td style={color}>{profile.handle}</td>
                    <td style={color}>
                      {profile.emails.map((email) => email.address).join(', ')}
                    </td>
                    <td style={color}>{profile.last_seen}</td>
                    <td style={color}>{profile.access.roles.join(' ')}</td>
                  </tr>
                )
              })
            )}
          </tbody>
        </table>
      </div>
    </Scrollbar>
  )
}

export default AdminUsers
