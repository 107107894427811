import React, { useState, useContext, useEffect, useRef } from 'react'
import { useQuery } from 'react-apollo'
import { Icon } from '../icons'
import { timeline, ACT_CLASS, ACT_CSS, ACT_CSSN } from '../../utils/journals'
import { JOURNAL_FEED } from '../../constants/Journal'
import JournalBoxRow from '../Journal/JournalRow'
import DaySpan from '../Journal/DaySpan'
import { handleWaitForLoadInline } from '../Handlers'
import moment from 'moment'
import Store, { UPDATE_JOURNALS } from '../../store'

/*
const INCREMENT = 5 // 15 // minute
const ROWS_PER_HR = 12 // 4 // Math.trunc(60 / INCREMENT) || 1 // don't make this a wierd number
const ROW_HEIGHT = 2 // 4 // Math.trunc(16 / ROWS_PER_HR)
*/
//const INCREMENT = 15 // minute
const ROWS_PER_HR = 4 // Math.trunc(60 / INCREMENT) || 1 // don't make this a wierd number
const ROW_HEIGHT = 4 // Math.trunc(16 / ROWS_PER_HR)

function Review(props) {
  const [{ journals, attribs }, dispatch] = useContext(Store)
  const sidebar = useRef(null)
  const [offset, setOffset] = useState(0)
  const [cache, setCache] = useState({})

  let increment
  let chart = cache[offset]
  useEffect(() => {
    if (!chart) {
      cache[offset] = timeline({ cache, attribs, journals }, { offset, increment })
      setCache(cache)
    }
  }, [cache])

  const { loading, error } = useQuery(JOURNAL_FEED, {
    onCompleted({ feed }) {
      dispatch({ type: UPDATE_JOURNALS, value: feed })
    }
  })
  const abort = handleWaitForLoadInline({ loading, error })
  if (abort) return abort

  return (
    <div>Refactoring - the old Review page is getting a face lift!</div>
  )
  const showCell = (x, y) => {
    sidebar.current.showData(x, y)
  }

  const defstyle = {} // border: "solid 1px red" }
  let prefs = {
    timeW: '10em',
    colW: '50',
    colH: '' + ROW_HEIGHT
  }

  prefs.rowstyle = { ...defstyle }
  prefs.colstyle = { ...defstyle, width: prefs.colW + 'px' }

  return (
    <div className="flex justify-center items-top v-top tc ma1 ma2-ns ma3-l">
      <div className="flex flex-column justify-center items-center v-mid tr align-right ba b--blue">
        <DaySpan />
      </div>
      <div className="flex flex-column justify-center items-center v-mid tc">
        <div
          className="flex justify-center items-center w-100 tc mb2"
          style={defstyle}
        >
          <button
            onClick={() => {
              setOffset(offset + 1)
              // showCell()
            }}
            className="hover navlink white bg-gray ma1 pa1 br1 mr2"
          >
            &laquo; back
          </button>
          <div className="white">
            {moment()
              .startOf('day')
              .subtract(offset, 'day')
              .format('YYYY MMM DD')}
          </div>
          <button
            onClick={() => {
              if (offset > 0) {
                setOffset(offset - 1)
                // showCell()
              }
            }}
            className="hover navlink white bg-gray ma1 pa1 br1 ml2"
          >
            forward &raquo;
          </button>
        </div>

        <div
          style={{ ...defstyle, backgroundColor: 'rgba(0,0,0,0.7)' }}
          className="pa3 flex flex-column"
        >
          <div className="flex">
            <div
              className="white f7"
              style={{ ...prefs.colstyle, width: prefs.timeW }}
            />
            <div className="flex">
              <div className="f7 white tc mr1" style={prefs.colstyle}>
                <Icon atype="summary" />
              </div>
              <div className="f7 white tc" style={prefs.colstyle}>
                <Icon atype="activity" />
              </div>
              <div className="f7 white tc" style={prefs.colstyle}>
                <Icon atype="phonecall" />
              </div>
              <div className="f7 white tc" style={prefs.colstyle}>
                <Icon atype="calendar" />
              </div>
              <div className="f7 white tc" style={prefs.colstyle}>
                <Icon atype="location" />
              </div>
            </div>
          </div>
          { /*
            // @ts-ignore */ }
          <ChartRows chart={chart} prefs={prefs} showfn={showCell} />
          <div className="flex mt3 mt3-ns">
            <div
              className="flex items-center white f7"
              style={{ ...prefs.colstyle, width: prefs.timeW }}
            >
              <div className="w-100 mr1 tr">legend:</div>
            </div>
            <div className="flex items-center justify-center w-100 tl">
              <div
                className="white pa1 f7 nowrap"
                style={{ backgroundColor: ACT_CSSN['work'] }}
              >
                <Icon acat="work" style={{ color: 'white' }} />
                Work
              </div>
              <div
                className="white pa1 f7 nowrap"
                style={{ backgroundColor: ACT_CSSN['personal'] }}
              >
                <Icon acat="personal" style={{ color: 'white' }} />
                Personal
              </div>
              <div
                className="bg-hot-pink white pa1 f7"
                style={{ backgroundColor: ACT_CSSN['conflict'] }}
              >
                Conflicts
              </div>
              <div
                className="bg-red white pa1 f7"
                style={{ backgroundColor: ACT_CSSN['unassigned'] }}
              >
                Un-assigned
              </div>
            </div>
          </div>
        </div>
      </div>

      <Sidebar ref={sidebar} data={{ cache, offset, journals }} />
    </div>
  )
}

class Sidebar extends React.Component {
  constructor(props) {
    super(props)
    this.state = { show: {} }
  }

  showData(x, y) {
    const { offset, cache } = this.props.data
    if (x === undefined || y === undefined) {
      this.setState({ show: {} })
    } else {
      this.setState({ show: cache[offset].links[x][y] })
    }
  }

  render() {
    let next = 0
    const show = this.state.show
    return (
      <div
        className="white ml3 pa3 v-top"
        style={{
          backgroundColor: 'rgba(0,0,0,0.7)',
          marginTop: '2.7rem',
          width: '40%'
        }}
      >
        <div className="v-100 bb b--moon-gray mb1">detail</div>
        {Object.keys(show).map((i, key) => {
          const item = show[i]
          return (
            <div key={next++}>
              <JournalBoxRow key={item.id} item={item} mutations={{}}/>
            </div>
          )
        })}
      </div>
    )
  }
}

function ChartRows(props) {
  const { chart, prefs, showfn } = props
  let { data } = chart
  // let now = (Date.now() / 1000)
  let row = 0
  let dataKeys = Object.keys(data)
  let accum = []
  const mapSubRow = (key, y) => {
    let col = 0
    return (
      <div className="flex tc" key={row++}>
        {data[key].map((val, x) => {
          // const width = x === 0 ? prefs.colW : prefs.colW / 2
          return Box(val, prefs.colW, prefs.colH, col++, () => {
            showfn(key, x)
          })
        })}
      </div>
    )
  }
  for (let x = 0; x < dataKeys.length; x += ROWS_PER_HR) {
    const subKeys = dataKeys.slice(x, x + ROWS_PER_HR)
    accum.push(
      <div
        key={row++}
        className="flex bb b--moon-gray"
        style={{ ...prefs.rowstyle }}
      >
        <div
          className="white f7"
          style={{ ...prefs.colstyle, width: prefs.timeW }}
        >
          {moment.unix(parseInt(subKeys[0])).format('h:mma')}
        </div>
        <div className="flex flex-column" style={{ ...prefs.rowstyle }}>
          {subKeys.map(mapSubRow)}
        </div>
      </div>
    )
  }
  return accum
}

function Box(cvalue, width, height, key, show, classes) {
  const cclass = ACT_CLASS[cvalue]
  const color = ACT_CSS[cvalue]
  /*
  if (key === 0) {
    boxstyle = {...boxstyle, marginRight: "0.2rem", borderRight: "solid 1px #ccc" }
  }
  */
  // GRR, style is not coming in with all the variants... PITA
  //let cclass = style['rtb' + color]
  return (
    <div className={`rtb rtb${cclass}`} onMouseOver={show}>
      <div
        style={{
          height: height + 'px',
          width: width + 'px',
          backgroundColor: color
        }}
      />
    </div>
    /*
    <svg onClick={show}
      xmlns='http://www.w3.org/2000/svg' version='1.1'
      width={`${width}px`} height={`${height}px`} key={key}>
      <defs />
      <g>
        <rect x='0' y='0' width={width} height={height} fill={bg} />
      </g>
    </svg>
    </div>
    */
  )
}

export default Review
