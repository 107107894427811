import gql from 'graphql-tag'

export const UPDATE_JOURNAL_PARTICIPANTS = 'UPDATE_JOURNAL_PARTICIPANTS'
export const UPDATE_JOURNAL_ACTIVITIES = 'UPDATE_JOURNAL_ACTIVITIES'
export const UPDATE_JOURNAL_GOALS = 'UPDATE_JOURNAL_GOALS'
export const STATE_TABS_CHANGED = 'STATE_TABS_CHANGED'
export const JOURNAL_VIEW_REFRESHED = 'JOURNAL_VIEW_REFRESHED'

const journalVars = `
      id
      memo
      start
      seconds
      end
      type
      attribs {
        id
        type
        label
        info
      }
      `
export const JOURNAL_FEED = gql`
  {
    feed {
      journals {
        ${journalVars}
      }
    }
  }
`

export const CREATE_ATTRIB = gql`
  mutation PostMutation($label: String!, $info: String!, $type: String!) {
    createAttrib(label: $label, info: $info, type: $type) {
      attrib {
        id
        type
        label
        info
      }
      journal {
        ${journalVars}
      }
    }
  }
`

export const LOG_ACTIVITY = gql`
  mutation PostMutation($time: String!, $memo: String!, $id: String) {
    logActivity(time: $time, memo: $memo, id: $id) {
      success
      reason
      result {
        ${journalVars}
      }
    }
  }
`

export const CREATE_LINK = gql`
  mutation PostLink($journal: String!, $attrib: String!) {
    createLink(journalId: $journal, attribId: $attrib) {
      ${journalVars}
    }
  }
`
export const REMOVE_LINK = gql`
  mutation bob($journal: String!, $attrib: String!) {
    removeLink(journalId: $journal, attribId: $attrib) {
      ${journalVars}
    }
  }
`
