import React, { useState, useContext } from 'react'
import { useMutation } from 'react-apollo'
import style from './index.module.css'
import { updateFeedItem, updateFeedCache } from '../../../utils/journals'
import { CREATE_ATTRIB } from '../../../constants/Journal'
import Store from '../../../store'

function AttribInput(props) {
  const [{ attribs }, dispatch] = useContext(Store)
  const [label, setLabel] = useState('')
  const info = ''
  const [icon, setIcon] = useState('')
  const [msg, setMsg] = useState(null)
  const [createAttrib] = useMutation(CREATE_ATTRIB)
  let type = props.type
  if (type.slice(0, 1) in ['#', '~', '@']) {
    type = type.slice(1)
  }

  return (
    <div className="flex flex-column">
      <div className="mt1 mt2-ns mt4-m flex items-center">
        <div>
          <input
            value={label}
            onChange={(e) => setLabel(e.target.value)}
            style={{ margin: 0 }}
            className={style.modalInput}
            placeholder="Label"
          />
        </div>
      </div>
      <div className="mt1 mt2-ns mt4-m flex items-center">
        <div>
          <input
            value={icon}
            onChange={(e) => setIcon(e.target.value)}
            onKeyDown={(e) => {
              if (!e.key.match(/[a-z0-9-]/i)) {
                e.preventDefault()
                e.stopPropagation()
                setMsg(
                  <>
                    Sorry, Font Awesome Icon Names are only characters a-z and
                    dashes
                  </>
                )
              }
            }}
            style={{ margin: 0 }}
            className={style.modalInput}
            placeholder="Icon Name"
          />
        </div>
        <div className="v-mid f6 pl1 pl2-m w-10 tr">
          <a
            href="https://fontawesome.com/icons?d=gallery&s=solid&m=free"
            rel="noopener noreferrer"
            target="_blank"
            className="no-underline"
          >
            (Font&nbsp;Awesome)
          </a>
        </div>
      </div>
      <input type="hidden" value={type} />
      <div className="mt3 tc fw2">{msg ? msg : null}</div>
      <div className="mt1 mt2-ns mt4-m">
        <button
          className={`${style.modalInput} button b ma0`}
          onClick={(e) => {
            createAttrib({
              variables: {
                label,
                info,
                icon,
                type
              },
              update(cache, { data: { createAttrib } }) {
                updateFeedCache(cache, dispatch, (journals) =>
                  journals.concat(updateFeedItem(createAttrib.journal, attribs))
                )
                props.viewState[1](false)
              }
            })
          }}
        >
          Save
        </button>
      </div>
    </div>
  )
}

export default AttribInput
