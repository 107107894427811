import React, { useState } from 'react'
import background from './signin.jpg' // eslint-disable-line
import { NavLink } from 'react-router-dom'
import NavBar from './NavBar'
import style from './index.module.scss'

const sizes = [1440, 720, 480]
let backgrounds = [{ img: 'austin-neill-247047-unsplash' }]

function UserProfile(props) {
  /*
  const browser = detect()

  const defBgPulse = browser.name === 'safari' ? false : true
  const [bgPulse, updateBgPulse] = useState(defBgPulse)
  // using state keeps it from changing when we refresh
  */
  const [bgimg] = useState(
    backgrounds[Math.floor(Math.random() * backgrounds.length)]
  )
  const srcset = sizes.map((size) => {
    return {
      url: `/assets/bgs/${bgimg.img}-${size}.jpg`,
      width: size
    }
  })
  /*
  useEffect(() => {
    if (context.userProfile.current) {
      const disable =
        context.userProfile.current.profile._raw.settings.disableBgMove
      updateBgPulse(!disable)
    }
  }, [context.userProfile])
  */

  return (
    <div className={style.bgWrapper}>
      <div
        className={`${style.background}`}
        style={{
          backgroundImage: 'url(' + srcset[0].url + ')'
        }}
      ></div>
      <div className={style.bgContent}>
        <NavBar />
        {props.children}
        <div
          className={`${style.bgCredit} flex items-center justify-between f7 w-100`}
        >
          <div className={style.embiggen}>
            {bgimg.title ? (
              <>
                backdrop:&nbsp;
                <i>
                  <b>{bgimg.title}</b>
                </i>
                &nbsp;
                {bgimg.artist ? (
                  <>
                    by&nbsp;
                    {bgimg.link ? (
                      <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href={bgimg.link}
                      >
                        {bgimg.artist}
                      </a>
                    ) : (
                      bgimg.artist
                    )}
                  </>
                ) : null}
              </>
            ) : null}
          </div>
          <div className={`${style.embiggen} ml-auto`}>
            <NavLink to="/support">
              Perform &trade; and &copy; 2020. All Rights Reserved.
              Privacy Policy &amp; Terms of Service
            </NavLink>
          </div>
        </div>
      </div>
    </div>
  )
}

export default UserProfile
