// import React, { Component } from 'react'

/* Draggable({
      ref: React.creatRef(),
      mutator: object,
      // even include an empty object, to establish it as a 'draggable' object
      drag: {onDragStart: func,
             onDragEnd: func,
             dragStartState: {..},
             dragEndState: {..}},
      // droppable
      drop: {onDrop: func,
             hoverStartState: {dragover: true},
             hoverEndState: {dragover: false},
             onDragEnter: func,
             onDragExit: func},
 })

 make sure to set id="x" on both drag src and dst objects, and "draggable" on the src object
    <
    id="xxx"
    ref={ref}
    draggable
    onDragStart={this.onDragStart}
    onDragEnter={this.drag.onDragEnter}
    onDragOver={this.drag.onDragEnter}
    onDragLeave={this.drag.onDragExit}
    onDrop={this.drag.onDrop}
    >
*/
class Draggable {
  constructor(args) {
    this.d = {}
    if (args.drag) {
      this.draggable = true
      Object.assign(this.d, args.drag)
    }
    if (args.drop) {
      Object.assign(this.d, args.drop)
    }
    this.self = args.self
    this.ref = args.ref
    this.mutator = args.mutator
  }

  selfSetState(args) {
    if (!args) {
      return
    }
    if (this.self) {
      this.self.setState(args)
    }
  }

  _hook(func, ev, args) {
    if (this.d[func]) {
      return this.d[func](ev, this.self, args)
    }
    return ev
  }

  /* enter hovering - causes bad behavior, so just ignore it */
  onDragEnter = (ev) => {
    ev.preventDefault()
  }

  /* hovering - lots of events, but more usable UX */
  onDragOver = (ev) => {
    ev.preventDefault()
    if (ev.dataTransfer.types.includes(this.d.accept)) {
      this.selfSetState(this.d.hoverStartState)
      return this._hook('onDragEnter', ev)
    }
  }

  /* leave hovering */
  onDragExit = (ev) => {
    ev.preventDefault()
    // always process exit
    this.selfSetState(this.d.hoverEndState)
    return this._hook('onDragExit', ev)
  }

  /* we started moving */
  onDragStart = (ev) => {
    ev.dataTransfer.setData(this.d.origin, '') // inelegant, but works
    ev.dataTransfer.setData('dragsrc', ev.target.id)
    this.selfSetState(this.d.dragStartState)
    return this._hook('onDragStart', this.d.onDragStart)
  }

  /* we finished moving (separate from drop) */
  onDragEnd = (ev) => {
    ev.preventDefault()
    this.selfSetState(this.d.dragEndState)
    return this._hook('onDragEnd', this.d.onDragEnd)
  }

  /* receiving */
  onDrop = (ev) => {
    const srcId = ev.dataTransfer.getData('dragsrc')
    const dstId = this.ref.current.id
    if (ev.dataTransfer.types.includes(this.d.accept)) {
      console.log({
        src: srcId,
        dst: dstId,
        ref: this.ref,
        mutator: this.mutator
      })
      this._hook('onDrop', ev, {
        src: srcId,
        dst: dstId,
        ref: this.ref,
        mutator: this.mutator
      })
      this.onDragExit(ev)
    }
  }
}

export default Draggable
