import React from 'react'
import style from './index.module.scss'
import Modal from 'react-modal'
import Scrollbar from '../Scrollbar'

function MyModal(props) {
  const {
    width,
    viewState: [modalOpen, setModalOpen],
    header,
    children,
    noPad,
    noScroll
  } = props

  // TODO: in the future address ariaHideApp
  // warn msg:
  //    Warning: react-modal: App element is not defined. Please use `Modal.setAppElement(el)` or set `appElement={el}`. This is needed so screen readers don't see main content when modal is opened. It is not recommended, but you can opt-out by setting `ariaHideApp={false}`.

  if (modalOpen) {
    return (
      <Modal
        isOpen={!!modalOpen}
        ariaHideApp={false}
        onRequestClose={() => setModalOpen(false)}
        className={`bg-frame flex-center ${style.modal}`}
        overlayClassName={style.overlay}
        style={{
          overlay: {
            backgroundColor: 'rgba(0,0,0,0.4)',
            backdropFilter: 'blur(2px)'
          }
        }}
      >
        <div
          className={`${style.frame} ${width ? style[width] : style['fw-50']}`}
        >
          {header ? (
            <div className={`${style.header} pa1 w-100`}>{header}</div>
          ) : null}
          <div
            className={`${style.close} pa1 flex-center br2`}
            onClick={() => setModalOpen(false)}
          >
            <i className="fas fa-times b" />
          </div>
          <div className={`${noPad ? '' : style.body} w-100`}>
            {noScroll ? (
              children
            ) : (
              <Scrollbar className="scroll2h">{children}</Scrollbar>
            )}
          </div>
        </div>
      </Modal>
    )
  } else {
    return null
  }
}

export default MyModal
