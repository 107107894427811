import React, { useState } from 'react'
import { GEN_APIKEY } from '../../constants/Profile'
import { useMutation } from 'react-apollo'
import { LoadingInline } from '../../components/Handlers'

////////////////////////////////////////////////////////////////////////////////
function Apikey({ subToggle }) {
  const [data, setData] = useState({ access: undefined, validation: undefined })
  const [genApiKey] = useMutation(GEN_APIKEY, {
    onCompleted({ genApiKey }) {
      setData(genApiKey)
    }
  })
  return (
    <div className="w-100 bg-frame">
      <div className="pv1 pv2-ns ph2 ph3-l tl bg-frame w-100 f6 b mr2 flex justify-between">
        <div>APIKEY</div>
      </div>
      <div className="ph1 ph2-m ph3-l w-100 ba b--transparent pb3 pt3">
        {subToggle ? (
          <>
            <button
              onClick={() => {
                setData(undefined)
                genApiKey()
              }}
            >
              Generate Integration Key
            </button>
            {!data ? (
              <LoadingInline />
            ) : data.access ? (
              <>
                <label className="mt2">ACCESS TOKEN (15 minute)</label>
                <div className="mv2 f7" style={{ wordWrap: 'break-word' }}>
                  {data.access}
                </div>
                <label>VALIDATION</label>
                <div className="mv2 f7" style={{ wordWrap: 'break-word' }}>
                  {JSON.stringify(data.validation)}
                </div>
              </>
            ) : null}
          </>
        ) : (
          <>API KEYS are available to subscribers</>
        )}
      </div>
    </div>
  )
}

export default Apikey
