import React from 'react'
// import config from './config'

export const VALIDATION_KEY = 'rtv' // + config.config.lane
export const ACCESS_KEY = 'rta' // + config.config.lane

// eventually query the backend for this
export const TENANT = {
  heading: (
    <div className="pt2 pt4-ns white">
      <div className="pa3 flex-center">
        <div className="pa3 f2 lh-copy fw5 tc text-outline">
          <i>PERFORM</i>
        </div>
      </div>
      <div className="flex-center flex-column mb3 mb5-ns">
        <div className="pa3 bg-frame tc i">
          <div className="mb2">Helping you with your day, because</div>
          <div>NOW is the most important moment you have</div>
        </div>
      </div>
    </div>
  ),
  terms: (
    <div className={`f7 tc mt2`}>
      <i>
        By using this account you agree to our{' '}
        <a href="/#/support">Privacy and Use Policy</a>
      </i>
    </div>
  ),
  passport_info: false,
  background: {
    type: 'random-picture'
  },
  federated: {
    google: {
      enabled: true,
      appId:
        '502594931942-hufril01k0hmhqdbaej2anhsk4m452ar.apps.googleusercontent.com'
    }
  }
}
