import { ApolloLink } from 'apollo-link'
import { ApolloClient } from 'apollo-boost'
import { InMemoryCache } from 'apollo-cache-inmemory'
import { createHttpLink } from 'apollo-link-http'

import { onError } from 'apollo-link-error'
import { setContext } from 'apollo-link-context'
import { refreshedTokenPromise, authError } from './utils/authx'
import { fromPromise } from 'apollo-link'

import config from './constants/config'

import debug from './utils/debug'
import { SIGNOUT_USER } from './store'

export const httpLink = createHttpLink({
  uri: config.baseurl + config.graphql,
  // I prefer this is 'include' but httpLink returns a network error in prod
  // if this is the setting.  something to look into later...
  // credentials: 'same-origin'
  credentials: 'include'
})

const apollo = (state, token, dispatch) => {
  let links = []

  if (token) {
    const authLink = setContext(async (_, { headers }) => {
      return {
        headers: {
          ...headers,
          authorization: token ? `Bearer ${token}` : ''
        }
      }
    })
    links = links.concat(authLink)
  }

  // an apollo link for error handling and refreshing if we are expired and need auth

  // need to fix - still doesn't work 100% correctly.  Problem: it doesn't retry
  // A few things to look into:
  //
  // - most promising:
  //   https://able.bio/AnasT/graphql-apollo-async-token-refresh--470t1c8
  //
  // - doesn't support graphql errors (sigh)
  //   https://www.apollographql.com/docs/link/links/retry/
  //
  // - dated solution, but maybe works.. just wrap GraphQL calls:
  //   https://medium.com/@lucasmcgartland/refreshing-token-based-authentication-with-apollo-client-2-0-7d45c20dc703

  let IS_REFRESHING = false
  let PENDING_REQUESTS = []

  const resolvePendingRequests = () => {
    PENDING_REQUESTS.map((callback) => callback())
    PENDING_REQUESTS = []
  }

  const errorLink = onError(
    ({ graphQLErrors, networkError, operation, forward }) => {
      console.log('apolloLink onError', graphQLErrors, operation, forward)

      if (graphQLErrors) {
        for (let err of graphQLErrors) {
          switch (err.message) {
            case 'Unauthenticated':
              debug('[apollo errorLink] unauthenticated error, refreshing...')

              let forward$
              if (!IS_REFRESHING) {
                IS_REFRESHING = true
                debug('[apollo errorLink] intercepting and refreshing token')

                forward$ = fromPromise(
                  refreshedTokenPromise()
                    .then(({ access_token: fresh_token }) => {
                      if (fresh_token) {
                        const headers = operation.getContext().headers
                        operation.setContext({
                          headers: {
                            ...headers,
                            authorization: `Bearer ${fresh_token}`
                          }
                        })
                        resolvePendingRequests()
                      } else {
                        dispatch({ type: SIGNOUT_USER })
                      }
                    })
                    // eslint-disable-next-line
                    .catch((error) => {
                      console.log('Caught error again')
                      PENDING_REQUESTS = []
                      authError({ state: state.user, status: {}, error })
                    })
                    // eslint-disable-next-line
                    .finally(() => {
                      console.log('Finally')
                      IS_REFRESHING = false
                    })
                )
              } else {
                // Will only emit once the Promise is resolved
                forward$ = fromPromise(
                  // eslint-disable-next-line
                  new Promise((resolve) => {
                    console.log('FORWARD', resolve)
                    PENDING_REQUESTS.push(() => resolve())
                  })
                )
              }

              return forward$.flatMap(() => forward(operation))

            default:
              debug('[apollo errorLink] errors', err)
          }
        }
      }

      if (networkError) {
        console.log(`[Network error]: ${networkError}`)
      }
    }
  )

  links = links.concat(errorLink)
  links = links.concat(httpLink)

  return new ApolloClient({
    cache: new InMemoryCache(),
    link: ApolloLink.from(links)
  })
}

export default apollo
