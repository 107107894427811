import React, { useState, useRef, useContext } from 'react'
import { useMutation } from 'react-apollo'
import { Icon } from '../../icons'
import pstyle from '../index.module.css'
import mystyle from './index.module.css'
import { secondsForHuman } from '../../../utils/time'
import { doLinkAttrib, hookCreateLinkUpdate } from '../../../utils/journals'
import { CREATE_LINK } from '../../../constants/Journal'
import Draggable from '../../Widgets/Draggable'
import Modal from '../../Modal'
import AttribInput from '../AttribInput'
import Store from '../../../store'

export function ListBoxRow({ item, createLink }) {
  const state = {
    dragover: false,
    bg: 'bg-gray'
  }
  const myRef = useRef(null)

  const drag = new Draggable({
    ref: myRef,
    drag: { origin: 'attrib' },
    drop: {
      accept: 'journal',
      onDrop: (ev, d, data) => doLinkAttrib(createLink, data),
      hoverStartState: { bg: 'bg-light-silver' },
      hoverEndState: { bg: 'bg-gray' }
    }
  })

  let sum = ''
  if (item.sum > 0) {
    sum = secondsForHuman(item.sum)
  }
  let icon = null
  if (item.icon) {
    icon = <Icon name={item.icon} classes="activity-icon gray" />
  }

  let classes = 'flex justify-between'
  classes += ' ' + state.bg
  classes += ' ' + mystyle.card
  return (
    <div
      className={classes}
      draggable
      ref={myRef}
      id={`m:${item.id}`}
      onDragEnter={drag.onDragEnter}
      onDragStart={drag.onDragStart}
      onDragOver={drag.onDragOver}
      onDragLeave={drag.onDragExit}
      onDrop={drag.onDrop}
    >
      <div className="mr-auto">
        {icon}
        {item.label}
      </div>
      <div>
        <small className="nowrap">{sum}</small>
      </div>
    </div>
  )
}

////////////////////////////////////////////////////////////////////////////////
function ListBoxAdd({ itemType, viewState }) {
  /*
  const  drag = new Draggable({
      drag: { origin: 'attrib' },
      drop: {
        accept: 'journal',
        onDrop: (ev, d, data) => doLinkAttrib(createLink, data),
        hoverStartState: { bg: 'bg-light-silver' },
        hoverEndState: { bg: 'bg-gray' }
      }
    })

  onDrop(ev, draggable, data) {
    if (data.mutator) {
      console.log('Would do create dialog here', data)
    }
    // data.mutator.doLinkChange(data)
  }
    const drag = this.drag

    ...
  */
  const title = 'Add to ' + itemType
  return (
    <Modal viewState={viewState} size="fw-50" header={title}>
      <AttribInput type={itemType} viewState={viewState} />
    </Modal>
  )
}

////////////////////////////////////////////////////////////////////////////////
function ListDown({ title, itemType, items, ...x }) {
  const dispatch = useContext(Store)[1]
  const [open, setOpen] = useState(false)
  const [createLink] = useMutation(CREATE_LINK, {
    update: hookCreateLinkUpdate(dispatch)
  })

  return (
    <div className={mystyle.boxlayer}>
      <div className="pa2">
        <div className={mystyle.header}>{title}</div>
        {Object.keys(items.all || []).map((id, index) => {
          const item = items.all[id]
          return <ListBoxRow key={id} item={item} createLink={createLink} />
        })}
      </div>
      <div
        className={`${pstyle.boxbot} hover-lighten pa1 pa2-l`}
        onClick={(e) => setOpen(true)}
      >
        <small>+ Add</small>
      </div>
      <ListBoxAdd itemType={itemType} viewState={[open, setOpen]} />
    </div>
  )
}

export default ListDown
