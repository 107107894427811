import { authNotify, authSignOn, authDebug } from '../../utils/authx/'

export function doSignOnLocal(
  { signup, handle, password, state, status },
  dispatch
) {
  authDebug('[AuthX].doLocalSignOn()', '')

  let vars = {
    signup: signup === 'signup',
    factor: 'email', // in the future: let people choose factors to auth with
    handle: handle.value.trim(),
    password: password.value.trim(),
    email: ''
  }

  if (signup) {
    vars.email = vars.handle
  }

  if (vars.handle.length === 0) {
    authNotify({ state, status }, 'Please provide an email address!', 'red')
    return Promise.resolve({ aborted: true })
  }
  if (vars.password.length === 0) {
    authNotify({ state, status }, 'Please provide a password!', 'red')
    return Promise.resolve({ aborted: true })
  }

  return authSignOn({ state, status, vars }, dispatch)
}
