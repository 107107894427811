import { secondsForHuman } from './time'
import { formatHumanRange } from './timeRange'
import { v4 as uuid } from 'uuid'
import moment from 'moment'
import { JOURNAL_FEED } from '../constants/Journal'
import { UPDATE_JOURNALS } from '../store'

/* bitwise operators will happen on these nums */
export const ACT_TYPES = {
  none: 0,
  work: 1,
  personal: 2,
  unmapped: 4,
  conflict: 8
}
export const ACT_CLASS = {
  /* moved these to base index.css for now */ 0: 'none',
  1: 'work',
  2: 'personal',
  4: 'unmapped',
  8: 'conflict'
}
export const ACT_CSS = {
  0: 'transparent',
  1: 'rgb(25,169,116)', // work
  2: 'rgb(53,126,221)', // personal
  4: 'rgb(255,65,54)', // unmapped
  8: 'rgb(255,65,180)' // conflict
}
export const ACT_CSSN = {
  none: 'transparent',
  work: 'rgb(25,169,116)',
  personal: 'rgb(53,126,221)',
  unmapped: 'rgb(255,65,54)',
  conflict: 'rgb(255,65,180)'
}

////////////////////////////////////////////////////////////////////////////////
export function updateFeedItem(item, attribs) {
  if (item._t === undefined) {
    // adjust time so it is in localtime, not zulu
    const time = moment(item.start)
    const duration = secondsForHuman(item.seconds)
    const hrmn = time.format('hh:mma').slice(0, -1) // drop the 'm' from am/pm
    item._t = {
      start: time,
      stime: time.unix(),
      hrmn: hrmn,
      duration: duration,
      edit: formatHumanRange(time.unix(), moment(item.end).unix()),
      //time.format("Y-M-DTHH:mmZZ") + " +" + duration,
      show: duration + ' @' + hrmn,
      show1: duration,
      show2: hrmn,
      day: time.format('dddd, MMMM, Do, YYYY')
    }
  }
  for (let y in item.attribs) {
    let attrib = item.attribs[y]
    attrib._label = attrib.label.toLowerCase()
    if (!attrib._info) {
      attrib._info = JSON.parse(attrib.info)
    }
    item._actct = 0

    if (!attribs[attrib.type]) {
      attribs[attrib.type] = {
        all: {}, // a list of all attributes of this type
        has: {} // a list of all items with this attribute
      }
    }
    let attrtype = attribs[attrib.type]

    // for answering: does item have 'activity': attribs.activity.has[item.id]
    if (!attrtype.has[item.id]) {
      attrtype.has[item.id] = { [attrib._label]: attrib.id }
    } else {
      attrtype.has[item.id][attrib._label] = attrib.id
    }

    // for knowing all attrs of a type
    if (!attrtype.all[attrib.id]) {
      attrtype.all[attrib.id] = {
        id: attrib.id,
        label: attrib.label,
        includes: {},
        icon: attrib._info.icon,
        detail: attrib._info.detail,
        info: attrib._info,
        sum: 0
      }
    }

    attrtype.all[attrib.id].includes[item.id] = true
    attrtype.all[attrib.id].sum += item.seconds
  }
  return item
}

export function updateFromFeed2(data, attribs) {
  let journals = data.journals
  if (!data._index) {
    data._index = {}
  }

  // not the most efficient way
  for (let id in attribs) {
    delete attribs[id]
  }

  // update items inline
  for (let x = 0; x < journals.length; x++) {
    // this is not immutably pure, we are updating the item pointer directly
    updateFeedItem(journals[x], attribs)
  }

  data._ordered = journals.sort((a, b) => {
    return a._t.stime - b._t.stime
  })

  // post scan, for locations and act category
  let locations = []
  for (let x in journals) {
    let item = journals[x]
    if (item.type === 'location') {
      locations.push(item)
    }
    // we have something, let's figure out what value to show
    item._actct = ACT_TYPES.unmapped // assume it isn't mapped yet
    if (attribs.activities && attribs.activities.has[item.id]) {
      let activities = attribs.activities.has[item.id]
      item._actct = ACT_TYPES.none
      for (let type in ACT_TYPES) {
        if (activities[type]) {
          item._actct |= ACT_TYPES[type]
        }
      }
    }
  }

  // scan just locations and update end times & duration
  const day_end = Date.now() / 1000
  for (let x = 0; x < locations.length; x++) {
    let item = locations[x]
    let next = locations[x + 1]
    if (item.seconds === 0) {
      let end = day_end
      if (next) {
        end = next._t.start.unix() + next._t.seconds
      }
      item.seconds = end - item._t.start.unix()
    }
  }

  return { journals: data, attribs }
}

export function timeline({ cache, journals, attribs }, opts) {
  if (!opts) {
    opts = {}
  }
  let { offset, increment } = opts

  if (!increment) {
    increment = 15 // minutes
  }
  if (!offset) {
    offset = 0 // DEFAULT should be 0, for dev tho...
  }
  const start = moment().startOf('day').subtract(offset, 'day')
  const end = moment().startOf('day').subtract(offset, 'day').add(1, 'day')

  // put it to seconds
  increment *= 60
  const start_t = start.unix()
  const end_t = end.unix() - 1

  // cols:  geo, tools, calendar, journal, phone, chat
  // cols:  geo, cal, jrn, phone
  let cols = {
    summary: 0,
    activity: 1,
    phonecall: 2,
    calendar: 3,
    location: 4
  }

  let chart = {
    data: {},
    links: {}
  }
  for (let x = start_t; x <= end_t; x += increment) {
    chart.data[x] = [
      ACT_TYPES.none,
      ACT_TYPES.none,
      ACT_TYPES.none,
      ACT_TYPES.none,
      ACT_TYPES.none
    ]
    chart.links[x] = [[], [], [], [], []]
  }

  let items = []

  // pre-scan
  for (let x in journals) {
    let item = journals[x]
    const i_start = item._t.start
    if (i_start <= start || i_start > end) {
      continue
    }
    items.push(item)
  }

  // update the duration for locations to be the next location update
  // TODO: (consider moving this to updateFromFeed())
  let day_end = Date.now() / 1000
  if (end_t < day_end) {
    day_end = end_t
  }

  let summary = {}

  // now scan everything
  for (let x in items) {
    let item = items[x]
    const i_start = item._t.start

    // scan through the time for this item
    let i_start_t = i_start.unix()
    i_start_t -= i_start_t % increment // round it off

    let i_end_t = i_start_t + item.seconds
    if (i_end_t > end_t) {
      // but more likely stop at when we are done
      i_end_t = end_t
    }
    i_end_t -= i_end_t % increment // round it off

    for (let y = i_start_t; y <= i_end_t; y += increment) {
      if (chart.data[y]) {
        // do we have a column? (if not we messed up somehow)
        if (!summary[y]) {
          summary[y] = [item]
        } else {
          summary[y].push(item)
        }
        let z = cols[item.type]
        // we have something, let's figure out what value to show
        if (z !== undefined && z !== null) {
          if (chart.data[y][z] > ACT_TYPES.none) {
            if (chart.data[y][z] !== item._actct) {
              chart.data[y][z] = ACT_TYPES.conflict
            }
          } else {
            chart.data[y][z] = item._actct
          }
        } else {
          console.log(`Ignoring unknown item type for chart ${item.type}`)
        }
      } else {
        console.log(`Error: out of bounds? chart start=${start_t} end=${end_t}`)
      }
    }
  }

  // and finally figure out a summary
  for (let x in summary) {
    let row = summary[x]
    const acts = row_set_actct(row)

    chart.links[x][0] = row
    if (acts.length === 1) {
      chart.data[x][0] = actct_map(acts[0])
    } else {
      // imperative... swap this w/deep learning on the backend eventually
      let rowmap = row_map_type(row)
      /* first priority, then down in order */
      if (rowmap.activity) {
        chart.data[x][0] = actct_map(rowmap.activity.category)
      } else if (rowmap.location) {
        chart.data[x][0] = actct_map(rowmap.location.category)
      } else if (rowmap.calendar) {
        chart.data[x][0] = actct_map(rowmap.calendar.category)
      } else if (rowmap.phonecall) {
        chart.data[x][0] = actct_map(rowmap.phonecall.category)
      }
    }
  }

  return chart
}

function row_set_actct(row) {
  return Array.from(
    new Set(
      row.map((item, y) => {
        return item._actct
      })
    )
  )
}
function row_map_type(row) {
  const map = {}
  for (let x in row) {
    const col = row[x]
    if (!map[col.type]) {
      map[col.type] = { category: 0, all: [] }
    }
    map[col.type].category |= col._actct
    map[col.type].all.push(col)
  }
  return map
}

function actct_map(num) {
  if (num === ACT_TYPES.work) {
    return ACT_TYPES.work
  } else if (num === ACT_TYPES.personal) {
    return ACT_TYPES.personal
  } else if (num > ACT_TYPES.personal) {
    return ACT_TYPES.conflict
  } else {
    return ACT_TYPES.unknown
  }
}

export async function pullData(client, component) {
  await client
    .query({ query: JOURNAL_FEED })
    .then((result) => {
      const {
        data: { feed }
      } = result
      component.setState({
        ...updateFromFeed2(feed, component.attribs),
        cache: {}
      })
    })
    .catch((err) => {
      console.log('ERR', this, err)
    })
}

export function hasFlag(value, flag) {
  return (value & flag) === 0
}

export function journalRowObj(row) {
  return updateFeedItem(
    {
      __typename: 'Journal',
      id: uuid(),
      start: Date.now(),
      seconds: 0,
      end: Date.now(),
      attribs: [],
      ...row
    },
    {}
  )
}

export function updateFeedCache(cache, dispatch, changeHook) {
  const data = cache.readQuery({ query: JOURNAL_FEED })
  data.feed.journals = changeHook(data.feed.journals)
  cache.writeQuery({ query: JOURNAL_FEED, data })
  dispatch({ type: UPDATE_JOURNALS, value: data.feed })
}

// for use with useMutation(CREATE_LINK) - returns hook with dispatch inserted
// ala:
//
//    update: hookCreateLinkUpdate(dispatch)
//
export function hookCreateLinkUpdate(dispatch) {
  return (cache, { data: { createLink: journal } }) => {
    const data = cache.readQuery({ query: JOURNAL_FEED })
    data.feed.journals = data.feed.journals.map((item) => {
      if (item.id === journal.id) return journal
      return item
    })
    cache.writeQuery({
      query: JOURNAL_FEED,
      data
    })
    dispatch({ type: UPDATE_JOURNALS, value: data.feed })
  }
}

export function doLinkAttrib(createLink, data, more) {
  const { src, dst } = data

  if (src[0] === dst[0] || src.slice(2) === dst.slice(2)) {
    return
  }

  let list = [src, dst]
  if (more)
    list.push(more)
  const items = list.reduce((acc, i) => {
    const id = i.slice(2)
    acc[i[0]] = id
    return acc
  }, {})

  return createLink({variables: { journal: items['j'], attrib: items['m'] }})
}
