import React, { useState, useContext, useRef } from 'react'
import { useMutation } from 'react-apollo'
import upstyle from '../index.module.css'
import mystyle from './index.module.css'
import { Icon } from '../../icons'
import Draggable from '../../Widgets/Draggable'
import Editable from '../../Widgets/Editable'
import PopMenu from '../../Widgets/PopMenu'
import { ACT_CSSN, ACT_CSS, ACT_TYPES, doLinkAttrib } from '../../../utils/journals'
import {
  REMOVE_LINK,
  LOG_ACTIVITY,
  JOURNAL_FEED
} from '../../../constants/Journal'
import { secondsForHuman } from '../../../utils/time'
import { logActivity } from '../JournalInput'
import Store, { UPDATE_JOURNALS } from '../../../store'

let _next = 0
function nextid() {
  return _next++
}

export function JournalRow({ item, mutations }) {
  const [{ filters }, dispatch] = useContext(Store)
  const [background] = useState('inherit')
  // const [removeLink] = useMutation(REMOVE_LINK)
  const myRef = useRef(null)
  // const [logActivityMutation] = useMutation(LOG_ACTIVITY)
  const { type, attribs } = item

  const drag = new Draggable({
    ref: myRef,
    self: this,
    mutator: {},
    drag: { origin: 'journal' },
    drop: {
      accept: 'attrib',
      onDrop: (ev, d, data) => doLinkAttrib(mutations.createLink, data),
      hoverStartState: { background: '#999' },
      hoverEndState: { background: 'inherit' }
    }
  })

  const onSave = (domElm, item, field) => {
    const vars = {
      id: item.id,
      memo: item.memo,
      time: item._t.edit
    }
    const input = domElm.textContent.trim()
    if (vars[field] !== input) {
      vars[field] = input
      logActivity({ mutation: mutations.logActivity, dispatch, ...vars })
    }
  }

  // lookup off linked item
  // eslint-disable-line
  // let activity = null
  let attribList = []

  // sort
  if (filters.toggle.attribs) {
    for (let ix in attribs) {
      let attrib = attribs[ix]
      let label = '#' + attrib.label
      switch (attrib.type) {
        // case 'activities':
        // activity = attribs[ix]
        // break
        case 'participants':
          label = '@' + attrib.label
          break
        default:
      }
      const dashlabel = label.replace(/ /g, '-')
      attribList.push(
        <PopMenu
          classes="hover-fade"
          key={nextid()}
          title={dashlabel}
          styles={mystyle}
        >
          <button
            className="hover-fade"
            onClick={() =>
              mutations.removeLink({
                variables: {
                  journal: item.id,
                  attrib: attrib.id
                },
                update(cache, { data: { removeLink: journal } }) {
                  const data = cache.readQuery({ query: JOURNAL_FEED })
                  data.feed.journals = data.feed.journals.map((item) => {
                    if (item.id === journal.id) return journal
                    return item
                  })
                  cache.writeQuery({
                    query: JOURNAL_FEED,
                    data
                  })
                  dispatch({ type: UPDATE_JOURNALS, value: data.feed })
                }
              })
            }
          >
            Remove
          </button>
        </PopMenu>
      )
    }
  }

  /*
    // TODO: this feels overly complex, look into using another method?  attribs.has?
    let activity_icons = []
    if (attribs) {
      activity_icons = Array.from(new Set(attribs.filter(x => x._info.icon && x.type === 'activities').map(x => x._info.icon.toLowerCase()))).sort()
    }
    */
  // activity bar on the left always show the color
  let act_color = ACT_CSS[item._actct]
  // icon, only show the color if it's not categorized
  let type_color =
    item._actct > ACT_TYPES.personal ? ACT_CSS[item._actct] : 'gray'
  let type_icon = (
    <Icon atype={type} classes={`rowIcon c<!>`} style={{ color: type_color }} />
  )
  let color = ''
  if (type === 'red') {
    color = 'red'
  }

  let EditableDiv = Editable('div')
  const isError = item.type === 'error'

  // I'd like to move this to <div>, but tables are handling it better (SIGH)
  // now for stupid hacks like the div spacer
  return (
    <tr
      style={{ backgroundColor: background }}
      draggable
      className="hover-darken"
      id={`j:${item.id}`}
      ref={myRef}
      onDragEnter={drag.onDragEnter}
      onDragStart={drag.onDragStart}
      onDragOver={drag.onDragOver}
      onDragLeave={drag.onDragExit}
      onDrop={drag.onDrop}
    >
      <td
        className="v-top tc h-100 pr1"
        style={{
          borderLeft: 'solid 4px ' + act_color,
          borderTop: 'solid 4px transparent',
          borderBottom: 'solid 4px transparent'
        }}
      ></td>
      <td className="v-top tr nowrap f7 pr1 pt1">
        <div className="mt2 mb2 flex justify-between">
          <EditableDiv
            realvalue={item._t.edit}
            item={item}
            className="flex justify-between"
            focusEnd={true}
            xsave={(domElm, self) => {
              mutations.logActivity && onSave(domElm, item, 'time')
            }}
          >
            <div style={{ color: '#555' }}>{item._t.show2}:&nbsp;</div>
            <div>{item._t.show1}</div>
          </EditableDiv>
          <AddTimeMenu classes="hover-fade" styles={mystyle} journal={item} />
        </div>
      </td>
      <td className="v-top tc">
        <div className="mt2 mb2">{type_icon}</div>
      </td>
      <td className="w-100">
        <div className="mt2 mb2">
          {isError ? (
            <div className={`${color} pl1 w-100 `}>{item.memo}</div>
          ) : (
            <div className="flex flex-column">
              <div className={`${color} pl1 w-100 flex`}>
                <EditableDiv
                  item={item}
                  xsave={(domElm, self) => {
                    mutations.logActivity && onSave(domElm, item, 'memo')
                  }}
                >
                  {item.memo}
                </EditableDiv>
              </div>
              <div className={`flex f7 nowrap gray`}>{attribList}</div>
            </div>
          )}
        </div>
      </td>
      <td className="nowrap ml-auto pa0 pr1 pr2-ns pr3-l">
        <div className="flex items-center">
          {isError ? null : (
            <div className="mt1 mr1 mr2-ns mr3-l f7">
              <Icon
                behavior="attach"
                classes={`rowIcon gray p<! pa1> ${upstyle.panelHoverDarken} hover-unhide`}
              />
            </div>
          )}
        </div>
      </td>
    </tr>
  )
}

export function JournalRowPad(props) {
  return (
    <tr>
      <td>
        <div className="" style={{ minHeight: '.75rem' }} />
      </td>
    </tr>
  )
}

function JournalRowSpan(props) {
  return (
    <tr className="w-100" key={nextid()}>
      <td colSpan={5} className="mt4">
        {props.children}
      </td>
    </tr>
  )
}

export function JournalRowSummary(props) {
  const { summary } = props
  if (!summary) {
    return <></>
  }
  let total = 0
  for (let key in summary) {
    total += summary[key]
  }
  return (
    <JournalRowSpan key={nextid()}>
      <div className="w-100 flex mb1">
        {Object.keys(summary).map((key, i) => {
          let val = Math.ceil((summary[key] / total) * 100)
          if (val > 0) {
            let human = secondsForHuman(summary[key])
            let color = ACT_CSSN[key] || ACT_CSSN['unmapped']
            if (color === 'transparent') {
              color = 'gray'
            }
            return (
              <div
                key={nextid()}
                className="pl1 pt1 hover-darken"
                style={{
                  borderBottom: 'solid 4px ' + color,
                  borderLeft: 'solid 4px ' + color,
                  borderBottomLeftRadius: '.5rem',
                  borderBottomRightRadius: '.5rem',
                  width: val + '%',
                  fontSize: '8pt'
                }}
              >
                {human}
              </div>
            )
          } else {
            return <></>
          }
        })}
      </div>
    </JournalRowSpan>
  )
}

function quarterFloat(number, fixed) {
  return (Math.round(number * 4) / 4).toFixed(fixed)
}

function AddTimeMenu({ styles, journal, classes }) {
  const [open, setOpen] = useState(false)

  const diff = Math.floor((new Date().getTime() / 1000 - journal._t.stime) / 60)
  const s_top = styles.menuTop || 'menu'
  const s_title = styles.menuTitle || 'menu-title'
  const s_items = styles.menuItems || 'menu-items'
  const s_item = styles.menuItem || 'menu-item'
  return (
    <div className={s_top}>
      <div onClick={() => setOpen(!open)} className={`${classes} ${s_title} br3`}>
        <i className="fas fa-plus gray" />
      </div>
      {open ? (
        <div className={`${s_items} ba`}>
          <div className={`${s_item} tl`}>
            Set to
            {diff < 480
              ? diff > 60
                ? ` ${quarterFloat(diff / 60, 2)}h`
                : ` ${quarterFloat(diff, 0)}m`
              : ` ${quarterFloat(journal.seconds / 60, 0)}m`}
          </div>
          <div className={s_item}> Set to 1h </div>
          <div className={s_item}> Set to 2h </div>
          <div className={s_item}> Set to 4h </div>
        </div>
      ) : null}
    </div>
  )
}

export function JournalRowDateline(props) {
  const { datestr } = props
  return (
    <JournalRowSpan key={nextid()}>
      <div className="f7 pt2 bt b--gray br3 mt2 mb2 pl2 gray">{datestr}</div>
    </JournalRowSpan>
  )
}

export default JournalRow
