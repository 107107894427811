import { authDebug } from './index'
// const authDebug = (x, y, z) => {}

export const redactedUserProfile = (profile) => {
  authDebug(3, '[authx/profile] redactedProfile()')
  return {
    roles: new Set(profile.access.roles),
    actions: new Set(profile.access.actions),
    loaded: true,
    id: profile.id,
    // eventually remove this
    _raw: profile
  }
}

////////////////////////////////////////////////////////////////////////////////
export function myProfile({ profile }) {
  if (profile) {
    return profile
  } else {
    return {}
  }
}

export function authCan(action, { profile }) {
  authDebug(3, '[authx/profile] authCan()', action)
  if (profile) {
    return profile.actions.has(action)
  }
  return false
}
