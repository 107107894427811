import React, { useContext } from 'react'
import { HashRouter as Router, Switch, Route, Redirect } from 'react-router-dom'

import AuthX from './components/AuthX'
import { authCan } from './utils/authx/profile'
import AdminUsers from './components/AdminUsers'
import Profile from './components/Profile'
import Root from './components/Root'
import Store from './store'
import Journal from './components/Journal'
import Plan from './components/Plan'
import Review from './components/Review'
import StaticPage from './components/StaticPage'
import PasswordReset from './components/Profile/PasswordReset'

export function App() {
  const [state] = useContext(Store)

  // const isAdmin = authCan('admin', state)
  const { user } = state

  const isAuthAdmin = authCan('auth_admin', state)

  return (
    <Router>
      <Root>
        <Switch>
          <Route
            exact
            path="/"
            render={() =>
              user.signedIn ? (
                <Redirect to="/journal" />
              ) : (
                <Redirect to="/signon" />
              )
            }
          />
          <Route path="/journal">
            <Journal context={state} />
          </Route>
          <Route path="/plan">
            <Plan context={state} />
          </Route>
          <Route path="/review">
            <Review context={state} />
          </Route>

          <Route path="/signon">
            <AuthX signout={false} />
          </Route>
          <Route path="/signout">
            <AuthX signout={true} />
          </Route>
          <Route path="/support">
            <StaticPage path="support.html" />
          </Route>
          <Route path="/pwreset">
            <PasswordReset />
          </Route>
          {user.signedIn ? (
            <>
              <Route path="/profile/:tab?">
                <Profile />
              </Route>
              {isAuthAdmin ? (
                <Route path="/adm/users">
                  <AdminUsers />
                </Route>
              ) : null}
            </>
          ) : null}
        </Switch>
      </Root>
    </Router>
  )
}

export default App
