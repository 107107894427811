import React, { useReducer, createContext } from 'react'

import { ApolloLink } from 'apollo-link'
import { ApolloClient } from 'apollo-boost'
import { InMemoryCache } from 'apollo-cache-inmemory'
import { httpLink } from '../apollo'
import { updateFromFeed2, journalRowObj } from '../utils/journals'
import { authSignOff } from '../utils/authx'
// import { v4 as uuid } from 'uuid'

export const SET_APOLLO = 1
export const SET_INTERFACE_CONFIG = 2
export const SET_PROFILE = 3
// export const SET_GAMESYSTEM = 4
export const SIGNOUT_USER = 5
export const UPDATE_USER = 6
export const UPDATE_JOURNALS = 7
export const UPDATE_FILTERS = 8
export const ADD_ERROR = 9

export const Store = createContext(null)

const initialState = {
  apollo: new ApolloClient({
    cache: new InMemoryCache(),
    link: ApolloLink.from([httpLink])
  }),
  user: {
    signedIn: false,
    signingIn: false,
    access_token: undefined,
    access_token_expires: 0,
    validation_token: undefined,
    splashScreen: true
  },
  journals: {},
  attribs: {},
  filters: {
    toggle: {
      location: false,
      calendar: false,
      activity: true,
      actsum: false,
      phonecall: false,
      goal: true,
      accomplishment: true,
      missed: false,
      attribs: false,
      error: true
    },
    limit: {}
  },
  errors: [],
  // TODO isn't profile a part of user?
  profile: undefined
}

const reducer = (state, action) => {
  switch (action.type) {
    case SET_APOLLO:
      let apollo = Object.assign({}, action.value)
      return { ...state, apollo }

    case SET_INTERFACE_CONFIG:
      return { ...state, interfaceConfig: action.value }

    case SET_PROFILE:
      return { ...state, profile: action.value }

    case UPDATE_USER:
      let user = Object.assign(state.user, action.value)
      return { ...state, user }

    case ADD_ERROR:
      const current = state.errors
      if (current.length > 0) {
        if (current[current.length - 1].memo === action.value.memo) {
          current[current.length - 1].count++
          return state
        }
      }
      return {
        ...state,
        errors: state.errors.concat(
          journalRowObj({
            memo: action.value.memo,
            count: 1,
            type: action.value.type || 'error'
          })
        )
      }

    case UPDATE_JOURNALS:
      // FUTURE: add marker for 'new' items and strip from old ones --
      // have UI flash the new items when it refreshes
      if (action.value) {
        return {
          ...state,
          ...updateFromFeed2(action.value, state.attribs),
          errors: []
        }
      }
      return { ...state, errors: [] }

    case SIGNOUT_USER:
      let signed_out = Object.assign(state.user, {
        signedIn: false,
        access_token: undefined,
        access_token_expires: 0,
        splashScreen: true
      })

      authSignOff()

      return { ...state, profile: undefined, user: signed_out }

    case UPDATE_FILTERS:
      return { ...state, filters: action.value }

    default:
      throw new Error('no such action.type!')
  }
}

export const StoreProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState)

  return <Store.Provider value={[state, dispatch]}>{children}</Store.Provider>
}

export default Store
