import React, { useEffect, useContext } from 'react'
import { ApolloProvider } from 'react-apollo'

import Background from './Background'
// import NavBar from './NavBar'

import Store, { SET_PROFILE } from '../../store'
import { refreshToken } from '../../utils/authx'
import { redactedUserProfile } from '../../utils/authx/profile'
import { READ_PROFILE } from '../../constants/Profile'

const Root = function ({ children }) {
  const [state, dispatch] = useContext(Store)

  // Bootstrap user based on validation/access_token changes
  useEffect(() => {
    refreshToken(state.user, dispatch)
  }, [dispatch, state.user, state.user.access_token, state.user.validation_token])

  // Fetch profile, etc, if user is signedIn
  useEffect(() => {
    const signedIn = state.user.signedIn
    if (signedIn) {
      if (!state.profile) {
        state.apollo
          .query({ query: READ_PROFILE })
          .then(({ data: { myProfile } }) => {
            dispatch({
              type: SET_PROFILE,
              value: redactedUserProfile(myProfile)
            })
          })
      }
    }
  }, [dispatch, state.apollo, state.profile, state.user.signedIn])

  return (
    <ApolloProvider client={state.apollo}>
      <Background>{children}</Background>
    </ApolloProvider>
  )
}

export default Root
