import jwt from 'jsonwebtoken'
import { v4 as uuid } from 'uuid'
import { readableError } from '../../components/Handlers'
import { safeStoreGet, safeStorePut, safeStoreDrop } from './safeLocalStore'
import config from '../../constants/config'
import { ACCESS_KEY, VALIDATION_KEY } from '../../constants/AuthX'
import { SET_APOLLO, UPDATE_USER, SIGNOUT_USER } from '../../store'
import apollo from '../../apollo.js'
import axios from 'axios'
// import debug from '../debug'
// level 0 is nothing, higher levels are more verbosity
// const DEBUG_LEVEL = 5
// export function authDebug(level, message, data) {
//   if (level <= DEBUG_LEVEL) {
//     debug(message, data)
//   }
// }
export const authDebug = (x, y, z) => {}

// Setup the user, apollo & manage tokens
export function setupUser(user, token, dispatch) {
  let { expires } = validAccessToken(token)
  safeStorePut(ACCESS_KEY, token)
  dispatch({ type: SET_APOLLO, value: apollo(user, token, dispatch) })
  dispatch({
    type: UPDATE_USER,
    value: {
      signedIn: true,
      access_token: token,
      access_token_expires: expires,
      splashScreen: false
    }
  })
}

// Try to get/refresh token and then setupUser or SIGNOUT
export function refreshToken(user, dispatch) {
  if (!user.access_token) {
    let validation_token = safeStoreGet(VALIDATION_KEY)
    let access_token = safeStoreGet(ACCESS_KEY)
    let { valid } = validAccessToken(access_token)

    // Token and valid, do the good thing
    if (access_token && valid) {
      setupUser(user, access_token, dispatch)
    } else if (validation_token) {
      // No access token? try using validation token to refresh
      refreshedTokenPromise().then(({ access_token: refreshedToken }) => {
        if (refreshedToken) {
          setupUser(user, refreshedToken, dispatch)
        } else {
          dispatch({ type: SIGNOUT_USER })
        }
      })
    }
  }
}

////////////////////////////////////////////////////////////////////////////////

export function authSignOn({ state, vars, status }, dispatch) {
  authDebug(1, '[utils/authx] authSignOn()]')
  return authRequest('signon', {
    body: JSON.stringify(vars)
  })
    .then((data) => postSignOn({ state, status, data }, dispatch))
    .catch((error) => authError({ state, status, error }))
}

export function authSignOff() {
  authDebug(1, '[utils/authx] authSignOff()]')
  safeStoreDrop(ACCESS_KEY)
  safeStoreDrop(VALIDATION_KEY)
}

export function authRequest(path, opts) {
  authDebug(3, '[utils/authx] authRequest() path=', path)
  if (!opts.headers) {
    opts.headers = {}
  }
  if (!opts.headers['Content-Type']) {
    opts.headers['Content-Type'] = 'application/json'
  }
  return axios
    .post(config.baseurl + config.authapi + path, opts.body, {
      headers: opts.headers,
      withCredentials: true
    })
    .then((res) => {
      return res.data
    })
}

export function authNotify({ state, status }, text, color) {
  authDebug(3, '[utils/authx] authNotify()')
  if (!color) {
    color = 'dark'
  }
  if (status.setStatus) {
    status.setStatus(text)
  } else {
    console.log(`CANNOT NOTIFY: ${text}`)
  }
}

export function authError({ state, status, error }) {
  authDebug(3, '[utils/authx] authError() err=', error)
  authNotify({ state, status }, readableError(error), 'red')
}

////////////////////////////////////////////////////////////////////////////////

export function refreshedTokenPromise() {
  const validation_token = safeStoreGet(VALIDATION_KEY)

  return authRequest('refresh', {
    body: JSON.stringify({
      client_assertion_type:
        'urn:ietf:params:oauth:client-assertion-type:jwt-bearer',
      client_assertion: genRefreshToken(validation_token)
    })
  })
}

export function genRefreshToken(validation_token) {
  if (validation_token) {
    const { secret, subject, audience } = validation_token
    return jwt.sign(
      {
        jti: uuid(),
        sub: subject,
        aud: audience
      },
      secret,
      { expiresIn: 10 * 60 }
    )
  } else {
    return false
  }
}

export function validAccessToken(token) {
  authDebug(3, '[utils/authx] validAccessToken()')
  if (!token) {
    return { valid: false, expires: 0 }
  }

  const claims = jwt.decode(token)
  if (Date.now() / 1000 < claims.exp) {
    return { claims, token, expires: claims.exp * 1000, valid: true }
  }
  return {}
}

////////////////////////////////////////////////////////////////////////////////

function postSignOn({ state, status, data }, dispatch) {
  authDebug(2, '[utils/authx] postSignOn() data=', data)
  if (data.aud && data.sec && data.sub) {
    let token = {
      audience: data.aud,
      secret: data.sec,
      subject: data.sub
    }
    safeStorePut(VALIDATION_KEY, token)
    dispatch({ type: UPDATE_USER, value: { validation_token: token } })
  } else if (data.reason) {
    authNotify({ state, status }, data.reason, 'red')
  } else {
    authNotify(
      { state, status },
      'response received from backend with no genRefreshToken token? cannot continue',
      'red'
    )
  }
}
