/*
 Authentication View.
*/

import React, { useState, useContext } from 'react'
import style from './SignOnLocal.module.scss'
import { doSignOnLocal } from './doSignOnLocal'
import StatusBox from './StatusBox'
import Store, { UPDATE_USER } from '../../store'
// import debug from '../../utils/debug'
// import { Tab, Tabs, TabList, TabPanel } from 'react-tabs'
// import SignOnLocalTab from './SignOnLocalTab'

////////////////////////////////////////////////////////////////////////////////
export function SignOnLocal({ boxPadding }) {
  const [state, dispatch] = useContext(Store)
  const [status, setStatus] = useState('')
  const tabHeaders = [
    { txt: 'Sign In', tok: 'signin' },
    { txt: 'Sign up', tok: 'signup' }
  ]
  const tabHeadersMap = tabHeaders.reduce((map, item) => {
    map[item.tok] = item
    return map
  }, {})
  const [currentTab, setCurrentTab] = useState('signin')

  const tabClick = (item, index, event) => {
    event.preventDefault()
    dispatch({ type: UPDATE_USER, value: { signingIn: false } })
    setStatus('')
    // for (let x = 0; x < tabHeaders.length; x++) {
    //   let elem = tabHeaders[x]
    //   if (x === index) {
    //     tabHeaders[x].txt = tabHeaders[x].txt.toUpperCase()
    //   } else {
    //     const { txt } = tabHeaders[x]
    //     tabHeaders[x].txt = txt.charAt(0).toUpperCase() + txt.slice(1).toLowerCase()
    //   }
    // }
    setCurrentTab(tabHeaders[index].tok)
  }

  let handle
  let password

  return (
    <div className={style.tabs}>
      <div className={`${style.tabList}`}>
        {tabHeaders.map((item, index) => {
          return (
            <div
              onClick={(e) => tabClick(item, index, e)}
              className={`${style.tab} ${
                item.tok === currentTab ? style.selected : ''
              }`}
              key={index}
            >
              {item.txt}
            </div>
          )
        })}
      </div>
      <div className={`${boxPadding} ${style.tabPanel} pb1`}>
        <div className={`pt3 pt4-ns items-center`}>
          <form>
            <input
              ref={(node) => {
                handle = node
              }}
              className="w-100 br2"
              placeholder="Your email"
            />
            <input
              ref={(node) => {
                password = node
              }}
              className="w-100 br2"
              type="password"
              placeholder={
                'Your ' + (currentTab === 'signup' ? 'desired ' : '') + 'password'
              }
            />

            <div className="flex justify-around mt3 items-center">
              <button
                className={`button auth-signin ${style.signinButton} w-100 items-center pa2`}
                style={{ border: 0, margin: 0 }}
                onClick={(e) => {
                  e.preventDefault()
                  dispatch({ type: UPDATE_USER, value: { signingIn: true } })
                  setStatus('')
                  doSignOnLocal(
                    {
                      state,
                      signup: currentTab,
                      status: { status, setStatus },
                      handle,
                      password
                    },
                    dispatch
                  ).finally(() => {
                    dispatch({ type: UPDATE_USER, value: { signingIn: false } })
                  })
                }}
              >
                <small className="label pl2">
                  {tabHeadersMap[currentTab].txt}
                </small>
              </button>
            </div>
            <StatusBox message={status} />
          </form>
        </div>
      </div>
    </div>
  )
}

export default SignOnLocal
