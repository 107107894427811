import React, { useState } from 'react'
import { UPDATE_PROFILE } from '../../constants/Profile'
import { useHistory } from 'react-router-dom'
import { useMutation } from 'react-apollo'
import Switch from 'react-switch'
import Modal from '../Modal'

////////////////////////////////////////////////////////////////////////////////
function FederatedAuthPrefs({ settings, changeSettings }) {
  const [googleAuth, setGoogleAuth] = useState(
    settings.authAllowed && settings.authAllowed.google
  )

  if (!settings.authAllowed) {
    settings.authAllowed = {}
  }
  return (
    <>
      <label>Federated Authentication</label>
      <div className="mv2 flex items-center pl2">
        <Switch
          onChange={() => {
            const value = !googleAuth
            setGoogleAuth(value)
            settings.authAllowed.google = value
            changeSettings(settings)
          }}
          checked={googleAuth}
          onColor="#F2C45E"
          offColor="#394247"
          height={12}
          width={30}
          className="mr2"
          activeBoxShadow="0 0 2px 3px white"
        />
        <div className="">Google Authentication (using the above email)</div>
      </div>
    </>
  )
}

function Toggle(props) {
  const { settings, changeSettings, label, keyword, subkey, invert } = props
  let setdict = settings
  if (subkey) {
    if (!settings[subkey]) {
      settings[subkey] = {}
    }
    setdict = settings[subkey]
  }

  // invert so it's on by default, even if not set
  const value = invert ? setdict[keyword] : !setdict[keyword]
  const [disable, setDisable] = useState(value)
  return (
    <>
      <div className="mv2 flex items-center pl2">
        <Switch
          onChange={() => {
            const value = !disable
            setDisable(value)
            if (invert ? value : !value) {
              setdict[keyword] = true
            } else {
              delete setdict[keyword]
            }
            changeSettings(settings)
          }}
          checked={!disable}
          onColor="#F2C45E"
          offColor="#394247"
          height={12}
          width={30}
          className="mr2"
          activeBoxShadow="0 0 2px 3px white"
        />
        <div className="">{label}</div>
      </div>
    </>
  )
}

function PlatformToggle({ settings, changeSettings, label, icon, setDirty }) {
  // invert so it's on by default, even if not set
  const key = label.toLowerCase()
  const [enabled, setEnabled] = useState(!!settings.platform[key])
  return (
    <div className="flex items-center mb2">
      <Switch
        onChange={() => {
          const value = !enabled
          setEnabled(value)
          if (value) {
            settings.platform[key] = true
          } else {
            delete settings.platform[key]
          }
          changeSettings(settings)
        }}
        checked={enabled}
        onColor="#F2C45E"
        offColor="#394247"
        height={12}
        width={30}
        className="mr2"
        activeBoxShadow="0 0 2px 3px white"
      />
      <i
        className={`fab fa-${icon} pl1 pr3 ${enabled ? 'fo-alt-yellow' : 'gray'}`}
      />
    </div>
  )
}

////////////////////////////////////////////////////////////////////////////////
function InputHandle(props) {
  const { settings, changeSettings, label, setDirty, enabled } = props

  // support it as an array - in the future update to allow more than one
  const key = label.toLowerCase()
  let handle = settings.handles[key]
  if (handle) {
    handle = handle[0]
  } else {
    handle = ''
  }

  let disabled = enabled ? false : !settings.platform[key]
  let value
  const upper = label.toUpperCase()
  return (
    <div>
      <label>{upper} HANDLE</label>
      <input
        ref={(node) => {
          value = node
        }}
        defaultValue={handle}
        disabled={disabled}
        onChange={() => setDirty(true)}
        onBlur={(e) => {
          const v = value.value
          if (v.length > 0) {
            settings.handles[key] = [v]
          } else {
            delete settings.handles[key]
          }
          changeSettings(settings)
        }}
        type="text"
        placeholder={handle || 'enter handle'}
        className={`w-100 ba b--gray br2 mb2 ${disabled ? 'noedit' : ''}`}
      />
    </div>
  )
}

////////////////////////////////////////////////////////////////////////////////
function InputPlatforms({ settings, handle, changeSettings, setDirty }) {
  const pargs = { settings, handle, changeSettings, setDirty }
  if (!settings.platform) {
    settings.platform = {}
  }
  if (!settings.handles) {
    settings.handles = {}
  }
  // let v_psn_handle, v_xbox_handle
  return (
    <>
      <label>PLATFORMS</label>
      <div className="mv2 flex items-start pl2 justify-between">
        <div className="">
          <PlatformToggle icon="playstation" label="PS4" {...pargs} />
          <InputHandle label="PS4" {...pargs} />
        </div>
        <div className="">
          <PlatformToggle icon="xbox" label="Xbox" {...pargs} />
          <InputHandle label="Xbox" {...pargs} />
        </div>
        <div className="">
          <PlatformToggle icon="windows" label="pc" {...pargs} />
          <InputHandle label="pc" {...pargs} />
        </div>
      </div>
    </>
  )
}

////////////////////////////////////////////////////////////////////////////////
function PerformHandle({ updateProfile, handle, dirty, setDirty }) {
  const [msg, setMsg] = useState(<></>)
  let v_handle
  const changeHandle = () => {
    if (dirty) {
      updateProfile({
        variables: { handle: v_handle.value },
        update: (cache, result) => {
          const { success, reason } = result.data.updateProfile
          if (success) {
            setDirty(false)
            setMsg(<></>)
            // if we only had "this" now...
            // this.refs.textInput.setNativeProps({'editable':false});
          } else {
            setMsg(<div className="pb2 f7">{reason}</div>)
          }
        }
      })
    }
  }
  return (
    <>
      <input
        ref={(node) => {
          v_handle = node
        }}
        defaultValue={handle}
        type="text"
        onKeyDown={(ev) => {
          if (ev.key === 'Enter') {
            changeHandle()
          }
          if (v_handle.value === handle) {
            setDirty(false)
          } else {
            setDirty(true)
          }
        }}
        onBlur={changeHandle}
        placeholder="Your Handle"
        className="w-100 ba b--gray br2 mb2"
      />
      {msg}
    </>
  )
}

////////////////////////////////////////////////////////////////////////////////
function Settings(props) {
  const { setDirty, dirty, updateProfile, makeChange, keyChange, profile } = props
  const history = useHistory()
  const args = new URLSearchParams(history.location.search)
  const [showOkay, setShowOkay] = useState(args.get('vok'))

  let { name, emails, handle } = profile
  return (
    <>
      <div className="w-100 mr1-l bg-frame">
        <div className="pv1 pv2-ns ph2 ph3-l tl bg-frame w-100 f6 b mr2 flex justify-between">
          <div>PERSONAL DETAILS</div>
          <i className={`fas fa-save ${dirty ? 'dark-red' : 'gray'} pointer`} />
        </div>
        <div className="flex justify-center">
          <div className="ph1 ph2-m ph3-l ba b--transparent pb3 pt3">
            <label>Real Name</label>
            <input
              ref={(node) => {
                name = node
              }}
              defaultValue={name}
              onChange={(e) => keyChange(e, { name: name.value })}
              onBlur={() => makeChange({ name: name.value })}
              type="text"
              placeholder="Your Name"
              className="w-100 ba b--gray br2 mb2"
            />
            <label>Perform Handle</label>
            <PerformHandle
              updateProfile={updateProfile}
              handle={handle}
              setDirty={setDirty}
              dirty={dirty}
            />
            <label>Email</label>
            <div className="w-100 ba b--light-gray br2 mb2 input noedit">
              {emails.map((email) => (
                <ShowEmail
                  email={email}
                  updateProfile={updateProfile}
                  emails={emails}
                />
              ))}
              <AddEmail />
            </div>
            <FederatedAuthPrefs {...props} />
            <label>VISUAL SETTINGS</label>
            <Toggle
              {...props}
              label="Background Movement"
              keyword="disableBgMove"
              invert={true}
            />
            <div className="f7 i">
              (the background will zoom in/out - high CPU but neat. Not supported
              with safari. Reload site after change)
            </div>
          </div>
        </div>
      </div>
      {showOkay ? (
        <Modal viewState={[showOkay, setShowOkay]} size="fw-50">
          <div
            className="flex-center flex-column fo-text-white"
            style={{ minHeight: '75vh' }}
          >
            <div>Email Verified!</div>
            <div className="mt3">{showOkay}</div>
            <button
              onClick={() => {
                setShowOkay('')
                history.push(history.location.path)
              }}
              className="mt3"
            >
              Okay
            </button>
          </div>
        </Modal>
      ) : null}
    </>
  )
}
/*
          <label>Recovery Phone</label>
          {phones.length > 0 ? (
            phones.map(phone => (
              <div
                key={phone.id}
                className="w-100 ba b--light-gray br2 mb2 input noedit"
              >
                {phone.number}
              </div>
            ))
          ) : (
            <div className="mb2 input noedit">(none)</div>
          )}
*/

function AddEmail(props) {
  const [adding, setAdding] = useState(false)
  const [newEmail, setNewEmail] = useState('')
  const [msg, setMsg] = useState(null)
  const [updateProfile] = useMutation(UPDATE_PROFILE, {
    update(cache, { data: updateProfile }) {
      if (updateProfile.success) {
        setAdding(false)
        setNewEmail('')
      } else {
        const { reason } = updateProfile.updateProfile
        setMsg(<div className="red mt2">{reason}</div>)
      }
    }
  })
  if (adding) {
    return (
      <>
        <div className="flex items-center">
          <input
            className="mt1 w-100"
            value={newEmail}
            autoFocus={true}
            placeholder="New Email Address"
            onChange={(ev) => {
              setNewEmail(ev.target.value.trim())
            }}
          />
          <button
            className="button pa1 ml2"
            onClick={() => {
              updateProfile({
                variables: { email: newEmail }
              })
            }}
          >
            Add
          </button>
        </div>
        {msg}
      </>
    )
  }
  return (
    <button
      className="button-clear-light f6 pa1 mb0 mt1 w-100 tl"
      onClick={() => setAdding(true)}
    >
      <i className="fas fa-plus mr2" />
      Add Email
    </button>
  )
}

function ShowEmail({ email, updateProfile, emails }) {
  const [check, setCheck] = useState(false)
  return (
    <div
      key={email.id}
      className="hover-outline flex justify-between items-center"
    >
      {email.address}
      <div className={`${email.verified ? 'green' : 'red'} ml2 f7 mr-auto`}>
        {email.verified ? (
          'verified'
        ) : (
          <>
            <button
              className="button-clear-light pa0"
              onClick={() => {
                updateProfile({ variables: { verifyemail: email.id } })
                setCheck(true)
              }}
            >
              resend verify email
            </button>
            {check ? <i className="ml2 fas fa-check green" /> : null}
          </>
        )}
      </div>
      {emails.length > 1 ? (
        <i
          className="fas fa-trash hover pa1 br0 pointer f7"
          onClick={() => {
            updateProfile({ variables: { rmemail: email.id } })
          }}
        />
      ) : null}
    </div>
  )
}
export default Settings
