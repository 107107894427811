import React from 'react'
import ReactDOM from 'react-dom'
import './styles/index.scss'
import App from './App'
import * as serviceWorker from './utils/serviceWorker'
import log from './utils/log'
import config from './constants/config'

import { StoreProvider } from './store'

log.event({ category: 'nav', action: '/' })
console.log(`BUILD=${config.build} APP=${config.baseurl}`)

// only include high level providers and configuration things here
ReactDOM.render(
  <StoreProvider>
    <App />
  </StoreProvider>,
  document.getElementById('root')
)

serviceWorker.unregister()
