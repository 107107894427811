import React, { useContext } from 'react'
import JournalBox from './JournalBox'
import JournalInput from './JournalInput'
import style from './index.module.css'
import JournalBarTypes from './JournalBarTypes'
import JournalBarActivities from './JournalBarActivities'
import ListDown from './ListDown'
import { Scrollbar } from '../Scrollbar'
import Store from '../../store'

function Journal(props) {
  const [{ attribs }] = useContext(Store)
  return (
    <div className={`flex items-top tc ${style.canvas}`}>
      <div
        className={`flex flex-column ${style.column} ${style.colsm} hide-medium`}
      >
        <ListDown items={attribs.goals || {}} title="Goals" itemType="goals" />
      </div>
      <div className={`${style.column} ${style.collg}`}>
        <div className={`${style.bgright} avenir br2`}>
          <JournalBarTypes />
          <Scrollbar className={`scroll ${style.scrollJBox}`}>
            <JournalBox />
          </Scrollbar>
          <JournalBarActivities />
        </div>
        <JournalInput />
      </div>
      <div
        className={`flex flex-column ${style.column} ${style.colsm} hide-medium`}
      >
        <ListDown
          items={attribs.accomplishment || {}}
          title="Accomplishments"
          itemType="accomplishment"
        />
      </div>
    </div>
  )
}

export default Journal
