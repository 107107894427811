import React from 'react'
import Inject from '../../components/Inject'
import Scrollbar from '../Scrollbar'
import style from './index.module.scss'

function StaticPage({ path }) {
  return (
    <div className="pa4-ns flex justify-center h-100">
      <div className="bg-frame">
        <Scrollbar className={`${style.scroll} bg-frame`}>
          <div className="ph2 pt2 pb4 pb2-ns">
            <Inject path={path} />
          </div>
        </Scrollbar>
      </div>
    </div>
  )
}

export default StaticPage
