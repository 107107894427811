import React, { useContext, useRef, useEffect } from 'react'
import { JournalRow, JournalRowSummary, JournalRowDateline } from '../JournalRow'
import { useQuery, useMutation } from 'react-apollo'
import { handleWaitForLoadInline } from '../../Handlers'
import { ACT_TYPES, ACT_CLASS } from '../../../utils/journals'
import {
  REMOVE_LINK,
  CREATE_LINK,
  LOG_ACTIVITY,
  JOURNAL_FEED
} from '../../../constants/Journal'
import Store, { UPDATE_JOURNALS } from '../../../store'

/* for future reference
https://codepen.io/drehimself/pen/KdXwxR
https://codepen.io/Varo/pen/YPmwpQ
*/

// when looking into delayed response firing: https://www.apollographql.com/docs/react/essentials/queries/#manually-firing-a-query
function JournalBox() {
  const [{ journals, filters, errors }, dispatch] = useContext(Store)
  const bottomRef = useRef(null)
  const { loading, error } = useQuery(JOURNAL_FEED, {
    onCompleted({ feed }) {
      dispatch({ type: UPDATE_JOURNALS, value: feed })
    }
  })
  const mutations = {
    createLink: useMutation(CREATE_LINK)[0],
    removeLink: useMutation(REMOVE_LINK)[0],
    logActivity: useMutation(LOG_ACTIVITY)[0],
  }


  useEffect(() => {
    if (bottomRef.current) {
      bottomRef.current.scrollIntoView()
    }
  }, [journals])

  const abort = handleWaitForLoadInline({ loading, error })
  if (abort) return abort
  // style={{ display: 'flex', flexDirection: 'column-reverse' }}>
  return (
    <>
      <table cellSpacing="0" cellPadding="0" className="pl1 pr1">
        <tbody>{mapWithDateSplits(journals._ordered, filters, errors, mutations)}</tbody>
      </table>
      <div ref={bottomRef} />
    </>
  )
}

function mapWithDateSplits(journals, filters, errors, mutations) {
  let result = []
  let cur_day = ''
  let next = 0

  let summary = {}
  for (let index in journals) {
    const item = journals[index]

    if (cur_day !== item._t.day) {
      if (filters.toggle.attribs === true) {
        result.push(<JournalRowSummary summary={summary[cur_day]} key={next++} />)
      }
      result.push(
        <JournalRowDateline
          summary={summary[cur_day]}
          datestr={item._t.day}
          key={next++}
        />
      )
      cur_day = item._t.day
    }
    if (!summary[cur_day]) {
      summary[cur_day] = {}
      for (var key in ACT_TYPES) {
        summary[cur_day][key] = 0
      }
    }
    if (!isNaN(item.seconds)) {
      if (item.type !== 'location') {
        summary[cur_day][ACT_CLASS[item._actct]] += item.seconds
      }
    }

    // TODO: update JournalData such that what is calculated on timeline for categories (work/play) is
    // calculated always, and then insert here a bar showing the day summary
    // chart = timeline(this.state, { offset: this.state.offset, increment: this.increment })
    if (filters.toggle[item.type] === undefined) {
      console.log(`item filter type=${item.type} is undefined`)
    }
    if (filters.toggle[item.type] !== false) {
      // this catches true and undefined (incase new types are added)
      result.push(<JournalRow key={item.id} item={item} mutations={mutations} />)
    }
  }
  if (filters.toggle.attribs === true) {
    result.push(<JournalRowSummary summary={summary[cur_day]} key={next++} />)
  }
  return result.concat(
    errors.map((err) => <JournalRow key={err.id} item={err} mutations={{}} />)
  )
}

export default JournalBox
