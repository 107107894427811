import React, { useState } from 'react'
import { UPDATE_PROFILE, READ_PROFILE } from '../../constants/Profile'
import { useMutation, useQuery } from 'react-apollo'
import handleWait from '../../components/Handlers'
import { useParams } from 'react-router-dom'
import Settings from './Settings'
import Subscriptions from './Subscriptions'
import Apikey from './Apikey'
import { ChangePassword } from './PasswordReset'
import NavTabs, { prepTabs } from '../NavTabs'

const TABS = prepTabs([
  {
    name: 'Settings',
    tok: 'settings'
  },
  {
    name: 'Subscription',
    tok: 'subs'
  },
  {
    name: 'Password',
    tok: 'pwd'
  },
  {
    name: 'APIkey',
    tok: 'apikey'
  }
])

function Profile() {
  const params = useParams()
  const [dirty, setDirty] = useState(false)
  // const [subToggle, setSubToggle] = useState(false)
  const [activeTab, setActiveTab] = useState(
    params.tab ? TABS.d[params.tab].x : 0
  )
  const curtab = TABS.l[activeTab]

  const { loading, error, data, refetch } = useQuery(READ_PROFILE, {
    fetchPolicy: 'cache-and-network'
  })
  const [updateProfile] = useMutation(UPDATE_PROFILE, {
    onCompleted({ data }) {
      setDirty(false)
    }
  })

  const abort = handleWait({ loading, error })
  if (abort) return abort

  const profile = data.myProfile
  let { settings } = profile
  if (!settings) {
    settings = {}
  }

  let subToggle = false
  if (profile.subscriptions && profile.subscriptions.length > 0) {
    subToggle = true
  }

  const makeChange = (vars, force) => {
    if (dirty || force) {
      updateProfile({
        variables: vars
      })
    }
  }
  const keyChange = (ev, vars) => {
    setDirty(true)
    if (ev.key === 'Enter') {
      ev.preventDefault()
      makeChange(vars)
    }
  }

  const changeSettings = (settings) => {
    makeChange({ settings: JSON.stringify(settings) }, true)
  }

  const pargs = {
    settings,
    changeSettings,
    dirty,
    setDirty,
    updateProfile,
    makeChange,
    keyChange,
    profile,
    subToggle,
    refetch
  }
  return (
    <NavTabs base="/profile" tabs={TABS} tabState={[activeTab, setActiveTab]}>
      <div className="mh1-ns mh3-m mh4-l mt3-ns mt4-l flex-l">
        {curtab.tok === 'settings' ? (
          <Settings {...pargs} />
        ) : curtab.tok === 'subs' ? (
          <Subscriptions {...pargs} />
        ) : curtab.tok === 'pwd' ? (
          <PasswordChanger settings={settings} />
        ) : curtab.tok === 'apikey' ? (
          <Apikey subToggle={subToggle} />
        ) : null}
      </div>
    </NavTabs>
  )
}

function PasswordChanger(props) {
  return (
    <div className="w-100 bg-frame">
      <div className="pv1 pv2-ns ph2 ph3-l tl bg-frame w-100 f6 b mr2 flex justify-between">
        <div>CHANGE PASSWORD</div>
      </div>
      <div className="flex justify-center">
        <div className="ph1 ph2-m ph3-l w-100 ba b--transparent pb3 pt3 w-50-l w-75-m">
          <ChangePassword inputCode={null} {...props} />
        </div>
      </div>
    </div>
  )
}

export default Profile
