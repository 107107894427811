import React, { useState } from 'react'
import { GET_INVITES, READ_PROFILE } from '../../constants/Profile'
import { useMutation } from 'react-apollo'
import { LoadingInline } from '../../components/Handlers'

////////////////////////////////////////////////////////////////////////////////
function Subscriptions(props) {
  const { dirty, setDirty, updateProfile, subToggle, refetch } = props
  const [inviteCode, setInviteCode] = useState('')
  const [subscribeErrMsg, setSubscribeErrMsg] = useState(<></>)

  const changeSubscription = (vars) => {
    setDirty(true)
    updateProfile({
      variables: vars,
      update: (cache, { data: { updateProfile } }) => {
        if (updateProfile.success) {
          cache.writeQuery({
            query: READ_PROFILE,
            data: { myProfile: updateProfile.profile }
          })
          refetch()
        } else {
          setSubscribeErrMsg(
            <div className="red i mt2">{updateProfile.reason}</div>
          )
        }
        setDirty(false)
      }
    })
  }

  return (
    <div className="w-100-l mr1-l">
      <div className="bg-frame ph1 ph2-m ph3-l w-100 ba b--transparent pb3 pt3 flex-l">
        <div className="">
          (Coming Soon) -- subscriptions will give you a longer data retention
          period, along with additional reports and powerful AI enhanced services.
        </div>
      </div>
    </div>
  )
}

/*

          <div className="mt2">
            We appreciate your understanding. As we work through Beta testing, the
            subscription is free, but is limited to invitations only.
          </div>
          <div className="mt2">
            <span className="fo-alt-yellow">
              If you would like a DADDY-O invitation
            </span>
            , find somebody who is already a DADDY-O and ask if they have an
            invitation code, or contact us on our discord (under the support
            option in the profile menu).
          </div>
          {subToggle ? <ShowInviteCodes className="mt2" /> : null}
        </div>
        <div className="w-50-l mt3 mt0-l flex w-100 items-start">
          <div
            className={`w-50 mr1 ba b--${
              subToggle ? 'transparent' : 'fo-alt-yellow'
            } pointer`}
            onClick={() =>
              changeSubscription({ unsubscribe: 'subscription_trader' })
            }
          >
            <div className="w-100 bg-fo-alt-yellow b pa1 flex items-center justify-between">
              <div>WANDERER</div>
              <div className="f6">No Cost</div>
            </div>
            <div className="pa2">
              Includes:
              <ul style={{ marginLeft: '-1.5rem' }}>
                <li>? active trade listings (tbd)</li>
                <li>? items of inventory (tbd)</li>
                <li>limited API/integrations</li>
              </ul>
              {subToggle ? null : (
                <>
                  <i
                    className={`fas fa-${
                      dirty ? 'save red' : 'check-square fo-alt-yellow'
                    }`}
                  />
                  <div className="dib ml2 i fo-alt-yellow">active</div>
                </>
              )}
            </div>
          </div>
          <div
            className={`w-50 ml1 ba b--${
              !subToggle ? 'transparent' : 'fo-alt-yellow'
            } hover`}
          >
            <div className="w-100 bg-fo-alt-yellow b pa1 flex items-center justify-between">
              <div className="ml1">DADDY-O</div>
              <div className="f6">
                <span className="strike">$1.50/month</span> free
              </div>
            </div>
            <div className="pa2">
              Includes:
              <ul style={{ marginLeft: '-1.5rem' }}>
                <li>Thousands of trade listings</li>
                <li>Thousands of items in inventory</li>
                <li>API key integrations, and more API calls allowed</li>
              </ul>
              {subToggle ? (
                <i
                  className={`fas fa-${
                    dirty
                      ? 'save red'
                      : subToggle
                      ? 'check-square fo-alt-yellow'
                      : 'square transparent'
                  }`}
                />
              ) : (
                <>
                  <div className="mv2">Currently available by invitation:</div>
                  <input
                    type="text"
                    placeholder="invitation code"
                    value={inviteCode}
                    onChange={(e) => setInviteCode(e.target.value)}
                  />
                  <button
                    onClick={() => {
                      if (inviteCode.length === 5) {
                        changeSubscription({ subscribe: '@' + inviteCode })
                        setSubscribeErrMsg(<></>)
                        setInviteCode('')
                      } else {
                        setSubscribeErrMsg(
                          <div className="red mt2 i">
                            Invitation code not found
                          </div>
                        )
                      }
                    }}
                    className="br2 pointer ml2 pa1 hover-lighten bg-fo-white"
                  >
                    <i className="fas fa-play" />
                  </button>
                </>
              )}
              {subscribeErrMsg}
            </div>
          </div>
        </div>
        */
function ShowInviteCodes(props) {
  const [invites, setInvites] = useState({ codes: [] })

  const [getInvites] = useMutation(GET_INVITES, {
    onCompleted({ getInvites }) {
      setInvites(getInvites)
    }
  })

  return (
    <div className={props.className}>
      <button
        className="pa1"
        onClick={() => {
          setInvites(undefined)
          getInvites()
        }}
      >
        Generate Daddy-O Invitation Code
      </button>
      {!invites ? (
        <LoadingInline />
      ) : invites.codes && invites.codes.length > 0 ? (
        <>
          <label className="mt2">
            INVITATION CODE{invites.codes.length > 1 ? 'S' : null}
          </label>
          <div className="mv2 flex flex-wrap">
            {invites.codes.map((code) => (
              <code key={code} className="mh1 bg-frame pa2">
                {code}
              </code>
            ))}
          </div>
          {invites.codes.length >= 5 ? (
            <div className="f7 i">
              These codes are unclaimed - after they are claimed you can generate
              more.
            </div>
          ) : null}
        </>
      ) : (
        <div className="mt2 ">
          You can invite friends by giving them one of your invitation codes.
        </div>
      )}
    </div>
  )
}

export default Subscriptions
