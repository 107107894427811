import React, { useState, useContext, useRef } from 'react'
import { Icon } from '../../icons'
import style from '../index.module.css'
import { secondsForHuman } from '../../../utils/time'
import { useMutation } from 'react-apollo'
import { doLinkAttrib, hookCreateLinkUpdate } from '../../../utils/journals'
import { CREATE_LINK, JOURNAL_FEED } from '../../../constants/Journal'
import Draggable from '../../Widgets/Draggable'
import Modal from '../../Modal'
import AttribInput from '../AttribInput'
import Store, { UPDATE_JOURNALS } from '../../../store'

function JournalBarActivity({ item, createLink, className, color }) {
  const myRef = useRef(null)
  let classes = className + ' ' + color
  const drag = new Draggable({
    ref: myRef,
    drag: { origin: 'attrib' },
    drop: {
      accept: 'journal',
      onDrop: (ev, d, data) => doLinkAttrib(createLink, data, 'm:' + item.id),
      hoverStartState: { bg: 'bg-light-silver' },
      hoverEndState: { bg: color }
    }
  })

  let sum = ''
  if (item.sum > 0) {
    sum = `(${secondsForHuman(item.sum)})`
  }
  let icon = null
  if (item.icon) {
    icon = <Icon name={item.icon} classes="activity-icon white" />
  }

  return (
    <div
      draggable
      ref={myRef}
      id={`m:${item.id}`}
      className={classes}
      onDragEnter={drag.onDragEnter}
      onDragStart={drag.onDragStart}
      onDragOver={drag.onDragOver}
      onDragLeave={drag.onDragExit}
      onDrop={drag.onDrop}
      style={{ fontWeight: 600, cursor: 'move' }}
    >
      <div>
        {icon}#{item.label}
      </div>
      <div className="ml2">
        <small className="nowrap">{sum}</small>
      </div>
    </div>
  )
}

function JournalBarActivities() {
  const [{ attribs }, dispatch] = useContext(Store)
  const [open, setOpen] = useState(false)
  const [createLink] = useMutation(CREATE_LINK, {
    update: hookCreateLinkUpdate(dispatch)
  })

  // TODO: do some calculations on screen sizes, limit based on this
  // for now, limit to 3 and add ...
  let items = {}
  let sublist = []
  let elipses = <></>
  if (attribs.activities) {
    items = attribs.activities.all
    const keys = Object.keys(items).reduce((acc, id) => {
      const label = items[id].label
      if (['work', 'personal'].includes(label)) {
        acc.unshift(id)
      } else {
        acc.push(id)
      }
      return acc
    }, [])

    sublist = keys.slice(0, 3)
    if (sublist.length < keys.length) {
      elipses = <div className={`${style.panel} mr1 pa1`}>...</div>
    }
  }
  let ex = 0 // fugly hack to do this outside of the closure
  return (
    <div className="flex justify-between">
      <div
        className="flex items-center v-mid tc pa0 w-100"
        onClick={(e) => setOpen(true)}
      >
        {sublist.map((id, index) => {
          let hide = ''
          if (ex > 1) {
            hide = 'hide-small'
          }
          ex++
          const item = items[id]
          let color = 'bg-gray'
          if (item.label.toLowerCase() === 'work') {
            color = 'bg-work'
          } else if (item.label.toLowerCase() === 'personal') {
            color = 'bg-personal'
          }
          return (
            <JournalBarActivity
              className={`flex justify-start pa1 mr1 w-50 mt1 mt2-ns ${hide}`}
              color={color}
              key={id}
              item={item}
              createLink={createLink}
            />
          )
        })}
        {elipses}
        <div className="pl1 mr1 mr2-ns mr3-l f7">
          <div className="fas fa-plus rowIcon pa1"></div>
        </div>
        <Modal viewState={[open, setOpen]} size="fw-50" header={'Add Activity'}>
          <AttribInput type="activities" viewState={[open, setOpen]} />
        </Modal>
      </div>
    </div>
  )
}

export default JournalBarActivities
