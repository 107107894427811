import { authDebug, authSignOn } from '../../utils/authx'

export function doSignOnFederated(
  { state, status, type, profile, authResponse },
  dispatch
) {
  authDebug('[AuthX].doSignOnFederated()')
  return authSignOn(
    {
      state,
      status,
      vars: {
        type: type,
        data: { profile: profile, auth: authResponse }
      }
    },
    dispatch
  )
}
