import React, { useContext } from 'react'
import { NavLink as InnerNavLink } from 'react-router-dom'
import style from './NavBar.module.scss'
import { MenuList, MenuItem, MenuButton } from 'react-menu-list'
import { authCan } from '../../utils/authx/profile'
import log from '../../utils/log'
import Store from '../../store'

function logNav(target) {
  log.event({ category: 'nav', action: '/' + target.toLowerCase() })
}
export function NavLink(props) {
  const { label, children, className, ...other } = props
  return (
    <InnerNavLink
      activeClassName={style.active}
      className={`${className} ${style.navlink} pa1`}
      onClick={() => {
        logNav(label)
      }}
      {...other}
    >
      {label || children}
    </InnerNavLink>
  )
}

// react-menu-list
function MenuLink(props) {
  const { className, ...other } = props
  return (
    <MenuItem>
      <NavLink
        className={`${className} w-100 tl`}
        {...other}
        onClick={() => {
          logNav(props.label)
        }}
      />
    </MenuItem>
  )
}

// todo:
// + add icons (gigstartup.xyz has them)
// + responsive collapse menus
export function NavBar(props) {
  const [state] = useContext(Store)
  const { user, profile } = state

  const isAuthAdmin = authCan('auth_admin', state)
  const isSummaryAdmin = authCan('summary_admin', state)

  let name
  let unread = 0
  if (profile) {
    if (profile._raw.handle.length > 0) {
      name = profile._raw.handle
    }
    if (profile._raw.unreadThreads) {
      // profile.unreadThreadCount =
      //   profile.unreadThreads.length
      unread = profile._raw.unreadThreads.length
    }
  }

  return (
    <div
      className={`${style.navbar} navbar f7 ma0 pa1 flex justify-between items-center`}
    >
      <NavLink to="/journal/" label="journal" />
      <NavLink to="/plan/" label="plan" />
      <NavLink to="/review/" label="review" />
      {user.signedIn ? (
        <MenuButton
          className={`${style.navlink} pa1`}
          positionOptions={{
            position: 'bottom',
            vAlign: 'top',
            hAlign: 'center'
          }}
          menu={
            <div className={`${style.navbar} f7 ba b--transparent `}>
              <MenuList>
                <MenuLink
                  to="/profile"
                  label="profile"
                  className={`${style.menuitem} pa2 `}
                />
                <MenuLink
                  to="/messages/"
                  label="messages"
                  className={`${style.menuitem} pa2 `}
                />
                <MenuLink
                  to="/help/"
                  label="help"
                  className={`${style.menuitem} pa2 `}
                />
                <MenuLink
                  to="/support"
                  label="support"
                  className={`${style.menuitem} pa2 `}
                />
                <MenuLink
                  to="/signout"
                  label="signout"
                  className={`${style.menuitem} pa2 `}
                />
                {isAuthAdmin || isSummaryAdmin ? (
                  <div className="bt b--gray mv1">
                    {isAuthAdmin ? (
                      <MenuLink
                        to="/adm/users"
                        label="user admin"
                        className={`${style.menuitem} pa2`}
                      />
                    ) : null}
                    {isSummaryAdmin ? (
                      <MenuLink
                        to="/adm/summary"
                        label="summary admin"
                        className={`${style.menuitem} pa2`}
                      />
                    ) : null}
                  </div>
                ) : null}
              </MenuList>
            </div>
          }
        >
          <div className="flex items-start w-100 justify-center">
            {unread ? (
              <i className="fas fa-circle red" style={{ fontSize: '6px' }} />
            ) : null}
            {name || 'me'}
            <i className="pl2 fas fa-bars" />
          </div>
        </MenuButton>
      ) : (
        <NavLink to="/signon/" label="signon" />
      )}
    </div>
  )
}

export default NavBar
