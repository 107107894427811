import React, { useState, useContext, useRef } from 'react'
import style from './index.module.css'
import { useMutation } from 'react-apollo'
import { JOURNAL_FEED, LOG_ACTIVITY } from '../../../constants/Journal'
import moment from 'moment'
import {
  parseRange,
  formatHumanRange,
  formatIsoRange
} from '../../../utils/timeRange'
import Store, { UPDATE_JOURNALS, ADD_ERROR } from '../../../store'

function invalidTimeMessage(badTime) {
  const start = moment().subtract(1, 'day')
  const earlier = moment().subtract(30, 'minutes')
  return (
    <div>
      Could not recognize time `{badTime}`, or it would be set in the future. Try some of:
      <ul className="list pl2">
        <li>
          now <i className="gray">&mdash; start right now, with no duration</i>
        </li>
        <li>
          30m <i className="gray">&mdash; start is 30 minutes before now</i>
        </li>
        <li>
          2.25h{' '}
          <i className="gray">&mdash; start is 2 hours 15 minutes before now</i>
        </li>
        <li>
          {start.format('hh:mma')} +30m{' '}
          <i className="gray">
            &mdash; start at {start.format('hh:mma')} today and add 30 minutes
          </i>
        </li>
        <li>
          {start.format('YYYY-MM-DD hh:mma')}{' '}
          <i className="gray">&mdash; start on date and hour, no duration</i>
        </li>
        <li>
          {earlier.format('hh:mma')} - {start.format('hh:mma')}{' '}
          <i className="gray">
            &mdash; start at {earlier.format('hh:mma')} today, and end at{' '}
            {start.format('hh:mma')}
          </i>
        </li>
      </ul>
    </div>
  )
}

function focus(el) {
  if (el) {
    el.current.focus()
  }
}
function alert(dispatch, msg) {
  dispatch({ type: ADD_ERROR, value: { memo: msg } })
}

export function logActivity({ mutation, dispatch, time, memo, ...optional }) {
  const { timeRef, id } = optional
  const memot = memo.trim()

  if (memot.length === 0) {
    alert(dispatch, 'No message to log?')
    return
  }
  if (time.length === 0) {
    alert(dispatch, 'No time for log?')
    return
  }

  const range = parseRange(time)
  if (!range || isNaN(range[0])) {
    alert(dispatch, invalidTimeMessage(time))
    focus(timeRef)
    return
  }
  mutation({
    variables: {
      id: id,
      time: formatIsoRange(...range),
      memo: memot
    },
    update(
      store,
      {
        data: {
          logActivity: { success, result, reason }
        }
      }
    ) {
      if (success === true) {
        // const mesg = res.message.replace(/GraphQL error: /, '')
        // refactor to capture error from server as {status}

        // pull data feed, update if it isn't in the index (otherwise assume it was an Editable field)
        const data = store.readQuery({ query: JOURNAL_FEED })
        if (data.feed._index && data.feed._index[result.id] !== undefined) {
          // re-sort data feed
          // TODO: NOT WORKING
          data.feed.journals = data.feed.journals.sort((a, b) => {
            return a._t.stime - b._t.stime
          })
        } else {
          // inefficient (sigh)
          let found = false
          for (let x in data.feed.journals) {
            const item = data.feed.journals[x]
            if (item.id === result.id) {
              data.feed.journals[x] = result
              found = true
            }
          }
          if (!found) {
            data.feed.journals.push(result)
          }
        }
        dispatch({ type: UPDATE_JOURNALS, value: data.feed })
        store.writeQuery({
          query: JOURNAL_FEED,
          data
        })
      } else {
        dispatch({ type: ADD_ERROR, value: { memo: reason } })
      }
    }
  })
}

export function JournalInput(props) {
  // const [state, dispatch] = useContext(Store)
  const dispatch = useContext(Store)[1]

  // const [id, setId] = useState('')
  const [time, setTime] = useState('now')
  const [memo, setMemo] = useState('')
  const [logActivityMutation] = useMutation(LOG_ACTIVITY)
  const timeRef = useRef(null)
  const memoRef = useRef(null)

  const doLogActivity = () => {
    logActivity({ mutation: logActivityMutation, dispatch, time, memo })
    setMemo('')
    focus(timeRef)
    // scrollToBottom()
  }

  // TODO: media breakpoint on the outer container such that the inputs
  // work better at small width screens
  return (
    <div className={style.bottom}>
      <div className={style.bottomFlex}>
        <input
          ref={timeRef}
          className={style.time}
          value={time}
          onChange={(ev) => setTime(ev.target.value)}
          placeholder="Time"
          autoComplete="off"
          onFocus={(e) => e.target.select()}
          onBlur={(e) => {
            if (time.trim() === '') {
              return
            }
            const ranges = parseRange(time)
            if (ranges) {
              const [stime, etime] = ranges
              setTime(formatHumanRange(stime, etime))
              focus(memoRef)
            } else {
              alert(dispatch, invalidTimeMessage(time))
              focus(timeRef)
            }
          }}
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              e.preventDefault()
              e.stopPropagation()
              focus(memoRef)
            }
          }}
        />
        <div className="hover-lighten pointer br3 bg-gray mh1 h-100">
          <i className="fas fa-user pa1" />
        </div>
        <input
          ref={memoRef}
          value={memo}
          className={style.memo}
          placeholder="Activity or action"
          onChange={(ev) => setMemo(ev.target.value)}
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              e.preventDefault()
              e.stopPropagation()
              doLogActivity()
            }
          }}
          onBlur={(e) => {
            // focus(timeRef)
          }}
        />
        <div
          className={`${style.push} fas fa-play fa-2x mr0`}
          onClick={(e) => doLogActivity()}
        />
      </div>
    </div>
  )
  // TODO: need scroll to bottom activator
}

export default JournalInput
