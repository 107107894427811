/*
 Authentication View.
*/

import React, { useContext } from 'react'
import { useHistory } from 'react-router-dom'
import style from './index.module.scss'
import { TENANT } from '../../constants/AuthX'
import SignOnFederated from './SignOnFederated'
import SignOnLocal from './SignOnLocal'
import { LoadingOverlay } from '../../components/Handlers'
import { navigate } from '../../utils/history'
import Scrollbar from '../Scrollbar'
import Store, { SIGNOUT_USER } from '../../store'

////////////////////////////////////////////////////////////////////////////////
export function Login({ signout }) {
  const history = useHistory()
  const [state, dispatch] = useContext(Store)

  if (signout) {
    dispatch({ type: SIGNOUT_USER })
    navigate(history, '/signon')
    return <></>
  }

  const path7 = history.location.pathname.substring(0, 7)
  if (path7 === '/signou') {
    dispatch({ type: SIGNOUT_USER })
    navigate(history, '/signon')
    return null
  } else {
    if (path7 === '/signon') {
      if (state.user.signedIn) {
        navigate(history, '/')
        return null
      }
    }
  }

  const boxPadding = 'ph3 ph4-ns'
  return (
    <Scrollbar style={{ maxHeight: '100vh' }}>
      <div className="mb6 mb0-ns">
        {TENANT.heading}
        {state.user.signingIn ? <LoadingOverlay /> : null}
        <div
          className={`${style.authx} flex items-start justify-center bg-frame ${style.authbox}`}
        >
          <div className="w-100">
            <SignOnLocal boxPadding={boxPadding} />
            <div
              className={`${style.innerpane} ${boxPadding} items-center ba b--transparent pb1`}
            >
              <SignOnFederated tenant={TENANT} />
            </div>
            <div
              className={`${style.innerpane} ${boxPadding} pv2 pv3-m pv4-l items-center ba b--transparent`}
            >
              <div className="i f7 tc">
                <a href="/#/pwreset?request=yes">Forgot Password?</a>
              </div>
              {TENANT.terms}
            </div>
          </div>
        </div>
      </div>
    </Scrollbar>
  )
}

export default Login
