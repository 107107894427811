import React, { useContext } from 'react'
import icons, { Icon } from '../../icons'
import style from './index.module.css'
import Tooltip from 'react-simple-tooltip'
import Store, { UPDATE_FILTERS } from '../../../store'

export function JournalBarTypes(props) {
  const [{ filters }, dispatch] = useContext(Store)
  let next = 0
  return (
    <div className={`${style.bar} flex justify-between bl b--black`}>
      {Object.keys(icons.atype).reduce((acc, type) => {
        const icon = icons.atype[type]

        if (icon.header) {
          acc.push(
            <Tooltip
              content={icons.atype[type].explain}
              className="w-100 p1"
              arrow={8}
              fadeDuration={1}
              padding={4}
              color="#fff"
              background="#444"
              placement="bottom"
              fontSize="10pt"
              radius={3}
              key={next++}
            >
              <Icon
                onClick={(ev) => {
                  filters.toggle[type] = !filters.toggle[type]
                  dispatch({ type: UPDATE_FILTERS, value: filters })
                }}
                key={next++}
                atype={type}
                toggleclass={(c) => {
                  return c + (filters.toggle[type] ? ' ' + style.active : '')
                }}
                classes={`${style.btn} p<! pa1 > w-100 white`}
              />
            </Tooltip>
          )
        }
        return acc
      }, [])}
    </div>
  )
}

export default JournalBarTypes
