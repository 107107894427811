import gql from 'graphql-tag'

const profileValues = `
    id
    emails {id,address,primary,verified}
    handle
    name
    phones {number,primary,verified}
    settings
    access { roles, actions }
`

export const READ_PROFILE = gql`
{
  myProfile {
    ${profileValues}
  }
}
`

export const PUBLIC_PROFILE = gql`
  query publicProfile($target: String!) {
    publicProfile(target: $target) {
      success
      reason
      profile {
        id
        handle
        platforms {
          platform
          handles
        }
        playstyles
      }
    }
  }
`

export const UPDATE_PROFILE = gql`
  mutation updateProfile($name: String, $settings: String, $handle: String, $subscribe: String, $unsubscribe: String, $email: String, $rmemail: String, $verifyemail: String) {
    updateProfile(name: $name, settings: $settings, handle: $handle, subscribe: $subscribe, unsubscribe: $unsubscribe, email: $email, rmemail: $rmemail, verifyemail: $verifyemail) {
      success
      reason
      profile {
        ${profileValues}
      }
    }
  }
`

// todo: add pagination and better filtering
export const LIST_PROFILES = gql`
query profiles($matching: String) {
  profiles(matching: $matching) {
    success
    reason
    total
    results {
      ${profileValues}
      last_seen
    }
  }
}
`

export const GEN_APIKEY = gql`
  mutation genApiKey {
    genApiKey {
      access
      validation
    }
  }
`

export const GET_INVITES = gql`
  mutation getInvites {
    getInvites {
      codes
    }
  }
`

export const REQUEST_PASSWORD_RESET = gql`
  mutation requestPasswordReset($email: String!) {
    requestPasswordReset(email: $email) {
      success
    }
  }
`

export const CHANGE_PASSWORD = gql`
  mutation changePassword($current: String, $new: String!, $email: String) {
    changePassword(current: $current, new: $new, email: $email) {
      success
      reason
    }
  }
`

export const MY_FACTORS = gql`
  query myFactors($type: String) {
    myProfile {
      id
      factors(type: $type) {
        id
        type
        expiresAt
      }
    }
  }
`
